import React from "react";
import { connect } from "react-redux";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { withStyles } from "@material-ui/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import TableCell from "@material-ui/core/TableCell";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import Dialog from "@material-ui/core/Dialog";
import CloseBar from "../../components//dialogCloseBar";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from "@material-ui/core/DialogContent";
import MultiSelect from "../../components/material-ui/MultiSelect";
import NumberField from "../../components/material-ui/NumberField";
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
} from "@material-ui/core";
import {
  setProjects,
  setProjectDataSet,
} from "../../../features/settings";
import {
  getDataNew,
  postDataNew,
  putDataNew,
} from "../../../core/fetchService";
import { NLU_TYPE_AC_OPTS } from "../../../core/constants";
import ConfirmDialog from "../../components/confirmDialog";
import { withTranslation } from "react-i18next";
import { IsAdmin, getRunConfigOption } from "../../../core/utils";

import notBackdropClicked from "../../components/helpers/notBackdropClicked";

import { SettingsMenu } from "../../pages/dashboard/SettingsDialog";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    flexWrap: "wrap",
  },
  paper: {
    padding: "18px",
    textAlign: "left",
  },
  textField: {
    marginTop: 8
  },
  column: {
    flexBasis: "33.33%",
  },
  heading: {
    fontSize: "12",
  },
  gridList: {
    overflow: "hidden",
    padding: 5
  },
  summary: {
    marginLeft: "20px",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
  },
  tdTopCell: {
    borderTop: "1px solid #000",
  },
  typography: {
    fontSize: 20
  },
  button: {
    marginTop: 10,
    marginLeft: 10
  }
});

const EMPTY_SIM_CONFIG_DETAILS = { models: {} };

class EditProject extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      project: null,
      showModal: false,
      modalContent: "",
      loading: true,
      similarityConfigDetails: EMPTY_SIM_CONFIG_DETAILS,
      configDefaults: null,
      savedValue: null,
    };
  };

  componentDidUpdate(prevProps) {
    if (this.props.open == prevProps.open)
      return;
    if (!this.props.open) {
      this.setState({ project: null, configDefaults: null, loading: true });
      return;
    }
    getDataNew('/api/project/named_configs', this.props.dispatch, data => {
      this.setState({ configDefaults: data });
    });
    this.props.id ? this.getProjectData() : this.setState({ loading: false })
  };

  user_groups = [];

  getProjectData = async (scd = true) => {
    if (IsAdmin())
      await getDataNew(`/api/user_group`, this.props.dispatch, data => { this.user_groups = data.user_groups });

    const url = `/api/project/${this.props.id}?${scd ? 'similarity_config_details=true' : ''}`;
    const res = await getDataNew(url, this.props.dispatch, data => {
      const access = (IsAdmin() && data.project.access || [])
        .map(i => this.user_groups.find(g => g._id == i) || { name: `--Not-Found-!--(${i})` });

      const newProject = { ...data.project, access };

      this.setState({project: newProject, similarityConfigDetails: data.similarity_config_details || EMPTY_SIM_CONFIG_DETAILS});
      if (data.project.datasets)
        this.props.dispatch(setProjectDataSet(data.project.datasets));
    })
    .then(() => this.setState({loading: false}));

    /* retry w/o similarity_config_details in case of error */
    res?.error && scd && this.getProjectData(false);
  };

  handleSetProjectData = (name, is_autocomplete) => (event, new_value) => {
    const value = is_autocomplete ? new_value : event.target.value;
    this.setState({ project: { ...this.state.project, [name]: value } });
  };

  handleUpdateProject = () => {
    const { projects } = this.props;
    const {
      id,
      name,
      description,
      access,
      nluConfig,
      similarityConfig,
      asrConfig,
      composerConfig,
    } = this.state.project;

    const project = {
      name,
      description,
      access: access?.map(g => g._id),
      nluConfig,
      similarityConfig,
      asrConfig,
      composerConfig,
    };

    const c = project.similarityConfig || {};
    if (!c.server || !c.model) {
      delete c.model;
      delete c.partition;
    } else if (!c.partition)
      delete c.partition;

    (id? putDataNew: postDataNew)(`/api/project/${id || ''}`, {
      project,
    }, this.props.dispatch, () => {
      this.props.dispatch(setProjects(projects.map(p => p._id == id ? {...p, ...project} : p)));
    })
    .then(() => this.props.handleCloseEdit())
    .then(() => this.props.getAllProjects())
  };
  
  showStatisticsDialog = () => {
    const { classes, t, id } = this.props;

    getDataNew(`/api/project/${id}/stats`, this.props.dispatch, data => {
      this.setState({
        showModal: true,
        modalContent: (
          <div className={classes.summary}>
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <GridList className={classes.gridList} cols={2}>
                  {[
                    t("projects.intent"),
                      "intentCount",
                      t("projects.slots"),
                      "slotCount",
                      t("projects.entities"),
                      "entityCount",
                  ].map((v, idx) => (
                    <GridListTile style={{ height: "auto" }} key={idx}>
                      <span>
                        {(() => {
                          if (idx%2 <= 0) return v;
                          return data[v] || 0;
                        })()}
                      </span>
                    </GridListTile>
                  ))}
                </GridList>
              </Grid>
              <Grid item xs={7}>
                <TableContainer>
                  <Table
                    stickyHeader
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell align="center">
                          {t("projects.entries")}
                        </TableCell>
                        <TableCell align="center" colSpan={2}>
                          {t("projects.annotated")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell align="center">
                          {t("projects.manual")}
                        </TableCell>
                        <TableCell align="center">
                          {t("projects.auto")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data?.datasets.map((ds, idx) => (
                        <TableRow key={idx}>
                          <TableCell component="th" scope="row">
                            {ds.name}
                          </TableCell>
                          <TableCell align="center">
                            {ds.rowCount || 0}
                          </TableCell>
                          {/* place manual */}
                          <TableCell align="center">
                            {ds.rowManualCount || 0}
                          </TableCell>
                          {/* place auto */}
                          <TableCell align="center">
                            {ds.rowAutoCount || 0}
                          </TableCell>
                        </TableRow>
                      ))}
                      <TableRow>
                        <TableCell
                          component="th"
                          scope="row"
                          className={classes.tdTopCell}
                        >
                          {t("common.all")}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.tdTopCell}
                        >
                          {data?.rowCount}
                        </TableCell>
                        {/* place sum by manual */}
                        <TableCell
                          align="center"
                          className={classes.tdTopCell}
                        >
                          {data?.rowManualCount}
                        </TableCell>
                        {/* place sum by auto */}
                        <TableCell
                          align="center"
                          className={classes.tdTopCell}
                        >
                          {data?.rowAutoCount}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </div>
        ),
      });
    });
  };

  onCancel = () => {
    this.setState({
      project: null,
      loading: true
    });
    this.props.handleCloseEdit()
  };

  render() {
    const { classes, t } = this.props;
    const { project, similarityConfigDetails, showModal, modalContent } = this.state;

    return (
        !this.state.loading && 
          <Dialog
            onClose={notBackdropClicked(() => this.onCancel())}
            open={this.props.open}
            fullWidth
            maxWidth='md'
          >
          <CloseBar onClose={this.onCancel} title={this.props.id? t('projects.edit_project'): t('projects.add_new_project')}/>
            <DialogContent>
              <div className={classes.root}>
                {showModal && (
                  <ConfirmDialog
                    maxWidth="md"
                    open={true}
                    btnNameDisagree={t("common.close")}
                    showBtnNameAgree={false}
                    title={t("train.summary")}
                    content={modalContent}
                    closeModal={() => { this.setState({ showModal: false }) }}
                  />)}
                <Grid
                  container
                  direction="column"
                  justify="space-evenly"
                  alignItems="stretch"
                  spacing={3}
                >
                  <Grid item>
                    <Paper className={classes.paper}>
                      <div style={{ paddingRight: 8 }}>
                        <TextField
                          required
                          size="small"
                          id="projectName"
                          label={t("common.name")}
                          defaultValue={project?.name}
                          variant="outlined"
                          className={classes.textField}
                          onChange={this.handleSetProjectData("name")}
                        />
                        <Button
                          className={classes.button}
                          disabled={!this.props.id}
                          variant="contained"
                          color="primary"
                          onClick={() => this.showStatisticsDialog()}
                        >
                          {t("common.statistics")}
                        </Button>
                        <TextField
                          required
                          size="small"
                          fullWidth
                          id="projectDescription"
                          label={t("common.description")}
                          multiline
                          rowsMax="4"
                          className={classes.textField}
                          style={{marginTop: 15}}
                          defaultValue={project?.description}
                          variant="outlined"
                          onChange={this.handleSetProjectData("description")}
                        />
                        {IsAdmin() && getRunConfigOption('enableRoleModel') ? (
                          <MultiSelect
                            id="access"
                            className={classes.textField}
                            defaultValue={project?.access}
                            onChange={this.handleSetProjectData("access", true)}
                            options={this.user_groups}
                            getOptionLabel={option => option.name}
                            label={t("projects.access")}
                          />) : null}
                      </div>
                      <div className={classes.summary} style={{ marginLeft: 8 }}>
                        <SettingsMenu
                          settings={project || {}}
                          setSettings={s => this.setState({ project: s })}
                          similarityConfigDetails={similarityConfigDetails}
                          defaults={this.state.configDefaults || {}}
                        />
                      </div>
                    </Paper>
                  </Grid>
                </Grid>
              </div>
            </DialogContent>
            <DialogActions>
              <Button disabled={!project?.name || !project?.description} onClick={() => this.handleUpdateProject()}>
                {t("common.save")}
              </Button>
              <Button onClick={this.onCancel}>
                {t("common.cancel")}
              </Button>
            </DialogActions>
        </Dialog>
    )
  }
}

const mapStateToProps = (state) => ({
  projects: state.settings.projects
});

export default connect(mapStateToProps)(
  withStyles(styles)(withTranslation()(EditProject)),
);
