import React, { Fragment, useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";

import { withStyles } from "@material-ui/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import GetAppIcon from "@material-ui/icons/GetApp";
import BlockIcon from "@material-ui/icons/Block";
import DeviceHubIcon from "@material-ui/icons/DeviceHub";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import TableCell from "@material-ui/core/TableCell";
import FormLabel from "@material-ui/core/FormLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Tooltip from "@mui/material/Tooltip";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "../../components/material-ui/RadioGroup";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";

import MuiIconButton from "@material-ui/core/IconButton";
import { SimpleMenu } from "../../components/material-ui/Menu";

import { NLU_NB_Settings as NLU_NB_SETTINGS } from "../../pages/dashboard/SettingsDialog";

import DateFnsUtils from "@date-io/date-fns";
import { subDays } from "date-fns";
import enLocale from "date-fns/locale/en-US";
import ruLocale from "date-fns/locale/ru";


import {
  MESSAGE_STATUS,
  DIALOG_USER_STATE,
  FILE_FORMATS,
  LANG,
}                                     from "../../../core/constants";
import {
  setDefaultRowsOnPage,
  download,
  showDate,
  getRunConfigOption,
  decodeMessage,
  showSuccess,
  showWarning,
  showError,
  isFileValidUTF8,
}                                     from "../../../core/utils";
import {
  getDataNew,
  deleteDataNew,
  uploadFileNew,
  postDataNew,
  putDataNew,
}                                     from "../../../core/fetchService";
import {
  fetchProjects,
  setIsLoading,
  setProjectDataSet,
  getSelectedDataSetId,
  setSelectedDataSetId,
  fetchDataSets,
  updateDataset,
  changeSelectedDataSetId,
}                                     from "../../../features/settings";
import { setMessageState }            from "../../../features/messageInfo";

import IconButton                     from "../../components/material-ui/IconButton";
import NumberField                    from "../../components/material-ui/NumberField";

import EnhancedTable, { viewLink }    from "../../components/projectTable";
import ConfirmDialog                  from "../../components/confirmDialog";
import Upload                         from "../../components/fieldUpload";
import CloseBar                       from "../../components/dialogCloseBar";

import {
  viewStatus,
  linkStyle,
  Runnable,
}                                     from "../../components/taskStatus";

import notBackdropClicked             from "../../components/helpers/notBackdropClicked";
import Scrollable                     from '../../components/helpers/scrollable';

import ComposerConfigDialog           from "./ComposerConfigDialog";


const UploadVariant = Object.freeze({
  File: "file",
  Composer: "composer",
  Copy: "copy",
});

const defaultDcImportConfig = Object.freeze({
  startDate: subDays(new Date(), 1),
  endDate: new Date(),
  quantity: 1,
  period: "month",
  status: "auto",
  role: "user",
  maxSize: 10000,
  ani: '',
  channel: '',
  first: true,
  glue: false,
  sanitize: false,
  update_intents: false,
  check_audio: true,
  date_type: "absolute",
});

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    flexWrap: "wrap",
    height: '100%',
    overflow: 'auto',
  },
  dialogPaper: {
    minWidth: "600px",
  },
  paper: {
    padding: "8px",
    textAlign: "left",
  },
  editLoadFromDCParamsRow: {
    marginTop: 10,
    display: 'flex',
    flexFlow: 'row nowrap',
    width: '95%',
  },
  smallTextField: {
    marginTop: 8,
    marginLeft: 10,
    height: "auto",
    width: "25%",
  },
  dateTextField: {
    height: "auto",
    width: "50%",
  },
  column: {
    flexBasis: "33.33%",
  },
  heading: {
    fontSize: "12",
  },
  gridList: {
    overflow: "hidden",
  },
  summary: {
    marginLeft: "20px",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
  },
  button: {
    marginRight: 10,
  },
  checkboxStart: {
    width: "35%",
    marginRight: "10px",
    whiteSpace: "nowrap",
  },
  checkboxLabelBeforeText: {
    fontSize: "0.9rem",
    marginTop: 19,
  },
  checkboxLabelBeforeRadio: {
    fontSize: "0.9rem",
    marginTop: 13,
    width: 65,
  },
});

const TabPanel = (props) => {
  const { children, value, index, visible, ...other } = props;
  return (
    <div
      {...other}
      role="tabpanel"
      hidden={value !== index || !visible}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

function viewDatasetStatus(row, cell) {
  const s_obj = row.execution;
  return viewStatus(row, cell, {
    s_obj,
    percent: s_obj?.progress?.percent,
    in_progress: {      /* Note: statuses' names (below) must match to runnables' params */
      vectorize:        'Vectorizing',
      upload:           'Loading',
      import_from_dc:   'Loading',
    }[s_obj.task_name],
  });
}

function viewStat(row, cell) {
  return row[cell._id] == 0 ? '' : row[cell._id];
}

function viewSize(row, cell) {
  return viewStat(row, cell);
  /*
  return row[cell._id] == 0 ? '-'
    : <Tooltip title={`manual: ${row.manual}, auto: ${row.auto}`}><div>{row[cell._id]}</div></Tooltip>;
    */
}

class Datasets extends React.Component{
  constructor(props) {
    super(props);
    this.state = this.getClearState_1();
    this.scrollable = new Scrollable(this);

    /* In fact, the actions (upload/import_from_dc and vectorize), corresponding to these
     * two runnables, are mutually exclusive. Still, it's simpler to use two similar runnables,
     * than to complicate the logic inside Runnable to support multiple statusName values.
     */
    this.loadable = new Runnable({
      taskNames: ['upload', 'import_from_dc'],
      getTaskName: ds => ds?.execution.task_name,
      getStatus: ds => ds?.execution?.status,
      statusName: 'Loading',
      updateStatus: this.checkStatus,
      addActiveStatuses: ['Fetching', 'Parsing'],
    });
    this.vectorizable = new Runnable({
      taskNames: ['vectorize'],
      getTaskName: ds => ds?.execution.task_name,
      getStatus: ds => ds?.execution?.status,
      statusName: 'Vectorizing',
      updateStatus: this.checkStatus,
      addActiveStatuses: ['Preparing dataset'],
    });
    this.runnables = [this.loadable, this.vectorizable];
  }

  getClearState = () => ({
    editDialogOpen: false,
    dataSetName: null,
    description: "",
    similarityConfig: undefined,
    formData: null,
    unique: false,
    lfdcFormContent: {
      intent_slot: "dummy",
      ...defaultDcImportConfig,
    },
    uploadProperties: {
      update_intents: false,
      check_audio: false,
    },
    tabValue: 0,
    header: false,
    role: "user",
    annStatus: "auto",
    radioValueFileType: "json",
    intentSlotChecked: true,
    aniChecked: false,
    channelChecked: false,
    copyProps: {
      keep_intents: true,
      keep_entities: true,
    },
    copySlice: {
      start: '',
      end: '',
   // length: undefined,
    },
  });

  getClearState_1 = () => ({
    ...this.getClearState(),
    showModal: undefined,
    maxWidth: false,
    uploadVariant: UploadVariant.File,
    isComposerConfigDialogOpen: false,
    modalContent: "",
    rowId: null,
    fileName: null,
    csvSeparator: "\\t",
    csvUtterance: "1",
    csvIntentField: "2",
    csvConfidence: "3",
    csvAudio: "4",
    showBtnNameAgree: true,
    showConfirmStatus: false,
  });

  getClearState_2 = () => ({ // TODO: same as 1
    ...this.getClearState(),
    absoluteDateChecked: false,
    relativeDateChecked: false,
 // csvSeparator: "\\t",
 // csvUtterance: "1",
 // csvIntentField: "2",
 // csvConfidence: "3",
 // csvAudio: "4",
  }) 

  isDatasetBeingProcessed = (...args) => this.runnables.some(r => r.isObjActive(...args));

  isStatusVectorizing = (dss, i) => this.vectorizable.isRunning(dss, i);
  isStatusLoading =     (dss, i) => this.loadable.isRunning(dss, i);

  isStatusRunning = (dss, i) => this.runnables.some(r => r.isRunning(dss, i));
  isActive =        (dss, i) => this.runnables.some(r => r.isActive(dss, i));

  componentDidMount()     {
    this.scrollable.componentDidMount();
    this.fetchProjectDatasets();
    this.fetchDefaultSettings()
  }

  componentDidUpdate()    { this.runnables.forEach(r => r.componentDidUpdate(this.props.projectDatasets)) }
  componentWillUnmount()  { this.runnables.forEach(r => r.componentWillUnmount()) }

  checkStatus = (datasets, adjust) => this.props.dispatch(fetchDataSets({ datasets, adjust }));

  adjustStatusTo = (sts, ds) => ({ ...ds, execution: { ...ds.execution, status: sts } });

  setStatusWaiting = dataset => {
    const { dispatch } = this.props;
    // this is to disable VECTORIZE/STOP icon in case a request is delayed/hanged up
    // with auto-update ON ('Waiting...' is in Runnable.isStatusActive)
    dispatch(updateDataset(this.adjustStatusTo('Waiting...', dataset)));
  }

  /*
  setStatusSaving = dataset => {
    const { dispatch } = this.props;
    // this is to disable VECTORIZE/STOP icon in case a request is delayed/hanged up
    // with auto-update OFF ('Saving...' is NOT in Runnable.isStatusActive)
    dispatch(updateDataset(this.adjustStatusTo('Saving...', dataset)));
  }
  */

  vectorize = (id, stop=false) => {
    const { t, dispatch, projects, projectId, projectDatasets } = this.props;
    const similarityConfig = this.state.configDefaults?.similarityConfig;
    const dataset = projectDatasets.find(ds => ds._id == id);
    if (!stop) {
      if (!similarityConfig?.server && !dataset.similarityConfig?.server)
        return showError(dispatch, t)('projects.error_no_server_url')
      if (!similarityConfig?.model && !dataset.similarityConfig?.model)
        return showError(dispatch, t)('projects.error_no_model')
    }
    this.setStatusWaiting(dataset);
    const [action, action_label] = stop
      ? (this.isStatusVectorizing(projectDatasets, id) ? ['stop_vectorize', 'datasets.vecn_stopped']
         : this.isStatusLoading(projectDatasets, id)   ? ['stop_upload', 'datasets.load_stopped']
         :                                               ['UNKNOWN', '_unk'])
      :                                                  ['vectorize', 'datasets.vectorizing'];
    getDataNew(`/api/dataset/${id}/${action}`, dispatch, data => {
      dispatch(setMessageState({
        snackBarMessages: t(action_label),
        snackBarVariant: stop ? MESSAGE_STATUS.INFO : MESSAGE_STATUS.SUCCESS,
        snackBarState: true,
      }));
      if (!stop)
        this.checkStatus([dataset]);
    }).finally(() => {
      if (stop)
        this.checkStatus([dataset]);
    });
  };

  fetchProjectDatasets = options => {
    const { projectId, dispatch } = this.props;
    dispatch(fetchDataSets({ projectId, ...options }))
      .then(() => dispatch(setSelectedDataSetId()));
  };

  fetchDefaultSettings = () =>
    getDataNew('/api/project/named_configs?project='+ this.props.projectId, this.props.dispatch, data => {
      this.setState({ configDefaults: data });
    });

  handleClickNewRow = (id) => {
    const { projectDatasets } = this.props;

    const dataset = projectDatasets?.find(ds => ds._id == id);
    const {
      name: dataSetName = null, description = "", importConfig = null, unique = true, similarityConfig,
    } = projectDatasets && id && dataset || {};

    const hasImportConfig = Boolean(projectDatasets.find(ds => ds._id == id)?.importConfig);
    this.setState({
      tabValue: Number(hasImportConfig),
      uploadVariant: hasImportConfig ? UploadVariant.Composer : UploadVariant.File,
      lfdcFormContent: { ...(importConfig || this.state.lfdcFormContent) },
      intentSlotChecked: Boolean(importConfig?.intent_slot),
      aniChecked: Boolean(importConfig?.ani),
      channelChecked: Boolean(importConfig?.channel),
      editDialogOpen: true,
      fileName: null,
      rowId: id,
      dataSetName,
      description,
      unique,
      similarityConfig,
    });
  };

  onFileLoad = (fileName, formData) => {
    this.setState({ fileName, formData});
  };

  handleDownloadFile = (id, name, fileFormat) =>
    download(`/api/dataset/${id}/download?format=${fileFormat}`, this.props.dispatch);

  handleDeleteRow = (id, name) => {
    this.setState({
      modalContent: `Delete data set: ${name}?`,
      showModal: true,
      maxWidth: "sm",
      rowId: id,
    });
  };

  handleCloseDeleteModal = modalState => {
    const { rowId } = this.state;
    this.setState({ showModal: false });
    if (rowId && modalState === DIALOG_USER_STATE.AGREE)
      deleteDataNew(`/api/dataset/${rowId}`, this.props.dispatch, data => {
        localStorage.removeItem("selectedDataSetId");
        this.fetchProjectDatasets({ stat: false });
      });
  };

  importFromComposer = async (rowId, button) => {
    const { dispatch, t } = this.props;
    const { dataSetName, description } = this.state;
    const {
      date_type,
      startDate,
      endDate,
      quantity,
      period,
    } = this.state.lfdcFormContent;

    const query = new URLSearchParams();
    query.append("date_type", date_type);
    if (date_type === "absolute") {
      query.append("startDate", new Date(startDate).toISOString());
      query.append("endDate", new Date(endDate).toISOString());
    } else if (date_type === "relative") {
      query.append("quantity", quantity);
      query.append("period", period);
    }
    [ "status", "role", "intent_slot", "ani", "channel",
      "maxSize", "first", "glue", "sanitize", "update_intents", "check_audio" ]
      .forEach(k => query.append(k, this.state.lfdcFormContent[k]));

    if (button == "save_import_settings") {
      const url = `/api/dataset/${rowId}/save-import-config?${query.toString()}`;
      return postDataNew(url, {}, dispatch, data => {
        this.checkStatus([{ _id: rowId }]);
        data.message && showSuccess(dispatch, t)(data.message);
      }, {
        error_prefix: t('datasets.save_import_settings_error'),
      });
    }

    const c = this.state.lfdcFormContent;
    const url = `/api/dataset/${rowId}/import_from_dc?${query.toString()}`;
    const data = {
      composerConfig: c && c.url && c.token ? c : undefined
    };
    return postDataNew(url, data, dispatch, data => {
      this.checkStatus([{ _id: rowId }]);
    }, {
      error_prefix: t('datasets.import_from_dc_error'),
    });
  };

  uploadDataset = async rowId => {
    const { dispatch, t, projectDatasets } = this.props;
    const {
      csvSeparator,
      csvUtterance,
      csvIntentField,
      csvConfidence,
      csvAudio,
      header,
      radioValueFileType,
      dataSetName,
      description,
      unique,
      uploadProperties,
      annStatus,
    } = this.state;

    let formData = this.state.formData;
    if (!await isFileValidUTF8(formData.get('file'),
                               m => {showWarning(dispatch, t)(m); return true},
                               e => {showError(dispatch, t)(e);   return false}))
      return false;
    formData.append("project", this.props.projectId);
    dataSetName && formData.append("name", dataSetName);
    formData.append("description", description);
    formData.append("unique", unique);

    const encodeQueryData = data =>
      Object.keys(data)
        .map(key => encodeURIComponent(key) +"="+ encodeURIComponent(data[key]))
        .join("&");

    let queryString = {
      format: radioValueFileType,
      author: this.props.userName,
      ...uploadProperties,
      status: annStatus,
    };

    if (radioValueFileType == "csv") {
      queryString = {
        ...queryString,
        delimiter: csvSeparator,
        text: csvUtterance || "",
        intent: (csvIntentField || "").trim(),
        confidence: (csvConfidence || "").trim(),
        audio: (csvAudio || "").trim(),
        header: header || "false",
      };
    }

    const url = `/api/dataset/${rowId}/upload?`+ encodeQueryData(queryString);

    uploadFileNew(url, formData, dispatch, data => {
      this.checkStatus([{ _id: rowId }]);
    }, {
      t,
      message_prefix: 'datasets',
      error_prefix: t('datasets.upload_error'),
    });

    return true;
  }

  saveDataset = async () => {
    const { rowId, dataSetName, description, unique, fileName, similarityConfig } = this.state;
    const { projectId: project, dispatch, projectDatasets } = this.props;

    if (rowId && fileName) {
        const dataset = projectDatasets.find(ds => ds._id == rowId);
        this.setStatusWaiting(dataset); // could have used 'Saving...' but just 'Waiting...' seems simpler
    }

    const sendData = rowId ? putDataNew : postDataNew;
    return await sendData('/api/dataset'+ (rowId ? '/'+ rowId : ''), {
      dataset: {
        name: dataSetName || fileName.replace(/.*[/\\]/).replace(/\.(json|txt|csv|tsv|xlsx?)$/, ''),
        description,
        ...(rowId ? {} : { unique }),
        project,
        similarityConfig,
      },
    }, dispatch, data => {
      /* When this request precedes the dataset loading request (/upload, /import_from_dc),
       * the dataset status blinks: X -> Waiting -> X(*) -> Preparing -> [next], where X is the initial status,
       * because this request returns (may return) X after Waiting has been sent.
       *
       * Therefore, we remove the (execution.)status field from the result (in case of successful request),
       * to avoid redundant changing status to X [marked with (*) above]
       */
      if (rowId) {
        const dataset = fileName ? this.adjustStatusTo('Waiting...', data.dataset) : data.dataset;
        dispatch(updateDataset(dataset));
      } else {
        this.fetchProjectDatasets({ stat: false });
      }

      return data.dataset._id;
    });
  };

  handleClose = async (isAccepted, button) => {
    const { dispatch } = this.props;

    if (isAccepted) {
      let { rowId } = this.state;

      if (this.state.uploadVariant != UploadVariant.Copy && button != 'save_import_settings')
        rowId = await this.saveDataset();

      switch (this.state.uploadVariant) {
        case UploadVariant.File:
          if (this.state.formData && !await this.uploadDataset(rowId))
              return false;
          break;

        case UploadVariant.Composer:
          this.importFromComposer(rowId, button);
          break;

        case UploadVariant.Copy:
          const query = new URLSearchParams();
          for (const name of ["keep_intents", "keep_entities"])
            query.append(name, this.state.copyProps[name]);
          for (const name of ["start", "end"])
            if (this.state.copySlice[name] !== '')
              query.append(name, this.state.copySlice[name]);
          await postDataNew(`/api/dataset/${this.state.rowId}/copy?`+ query, {}, dispatch, data => {
            this.fetchProjectDatasets({ stat: false });
          });
          break;

        default:
          break; // avoid no-default-case warning
      }
    }

    dispatch(setSelectedDataSetId());

    this.setState(this.getClearState_2());
  };

  handleChangeSwitch = (event) => {
    this.setState({
      radioValueFileType: event.target.value,
      annStatus: "auto",
    });
  };

  handleRowItemChanged = obj => {
    const { t } = this.props;
    putDataNew(`/api/dataset/${obj._id}`, {
      dataset: {
        [obj.rowName]: obj[obj.rowName],
        project: this.props.projectId,
      },
    }, data => {
      this.checkStatus([{ _id: obj._id }]);
    }, {
      error_prefix: t('datasets.save_error'),
    });
  };

  handleCheckboxChange = (event, key) => {
    this.setState({
      [key]: {
        ...this.state[key],
        [event.target.name]: event.target.checked,
      },
    });
  };

  handleUploadPropertiesChange = (event) => {
    this.handleCheckboxChange(event, 'uploadProperties');
  };

  handleCopyPropertiesChange = (event) => {
    this.handleCheckboxChange(event, 'copyProps');
  };

  handleTabChange = (event, tabValue) => {
    const uploadVariant = UploadVariant[['File','Composer','Copy'][tabValue] || 'File'];
    this.setState({ tabValue, uploadVariant });
  };

  handleOpenLoadConfig = (isAccepted) => {
    this.setState({
      isComposerConfigDialogOpen: isAccepted,
    });
  };

  handleLfdcFormContentFill = (name, value) => {
    const other = value
      ? (name == "sanitize" ? { update_intents: false }
         : name == "update_intents" ? { sanitize: false } : {})
      : {};
    this.setState(prevState => ({
      lfdcFormContent: {
        ...prevState.lfdcFormContent,
        [name]: value,
        ...other,
      },
    }));
  };

  handleCopySliceFill = (name, value) => {
 // const other = value
 //   ? (name == "start" ? { : false }
 //      : name == "end" ? { sanitize: false } : {})
 //      : name == "length" ? { sanitize: false } : {})
 //   : {};
    this.setState(prevState => ({
      copySlice: {
        ...prevState.copySlice,
        [name]: value,
 //     ...other,
      },
    }));
  };

  handleComposerDialogAccept = async (loadConfigFormContent, isReseted) => {
    if (isReseted) {
      await postDataNew(
        `/api/dataset/${this.state.rowId}/reset-composer-config`,
        {},
          this.props.dispatch
      );
    } else {
      const isComposerConfigValid = (config) =>
        config && config.url && config.token;

      this.setState({
        lfdcFormContent: {
          ...this.state.lfdcFormContent,
          ...isComposerConfigValid(loadConfigFormContent)
            ? loadConfigFormContent
            : null,
        },
      });
    }

    this.setState({
      isComposerConfigDialogOpen: false,
    });
  };

  handleComposerDialogReset = (loadConfigFormContent) => {
    this.setState({
      lfdcFormContent: {
        ...this.state.lfdcFormContent,
        loadConfigFormContent,
      },
    });
  };

  loadFromDialogComposerForm = (t, classes) => {
    return (<>
      <ComposerConfigDialog
        {...this.state.lfdcFormContent}
        defaultUrl={this.props.projectComposerConfig?.url}
        defaultProject={this.props.projectComposerConfig?.project}
        defaultApplicationId={this.props.projectComposerConfig?.applicationId}
        defaultToken={this.props.projectComposerConfig?.token}
        isOpen={this.state.isComposerConfigDialogOpen}
        onAccept={this.handleComposerDialogAccept}
        onReject={() => this.handleOpenLoadConfig(false)}
        onReset={this.handleComposerDialogReset}
      />
      <FormControl fullWidth component="fieldset">
        <RadioGroup
          style={{ display: "flex", marginLeft: "-16px" }}
          labels={['absolute','relative'].reduce((a,k) => {a[k]=t('datasets.'+ k +'_date'); return a}, {})}
          value={this.state.lfdcFormContent.date_type || "absolute"}
          onChange={event => this.handleLfdcFormContentFill("date_type", event.target.value)}
        />
        <FormGroup className={classes.editLoadFromDCParamsRow}>
          {(this.state.lfdcFormContent.date_type || "absolute") == "absolute" ? (<>
            <MuiPickersUtilsProvider
              utils={DateFnsUtils}
              locale={this.props.lang === LANG.RU ? ruLocale : enLocale}
            >
              <KeyboardDateTimePicker
                inputVariant="outlined"
                id="startDatePicker"
                ampm={false}
                disableFuture={true}
                minutesStep={5}
                className={classes.dateTextField}
                style={{ margin: 0, marginRight: 10 }}
                size="small"
                margin="normal"
                label={t("datasets.start_date")}
                okLabel={t("common.ok")}
                cancelLabel={t("common.cancel")}
                format="yyyy-MM-dd HH:mm"
                value={this.state.lfdcFormContent?.startDate || subDays(new Date(), 1)}
                onChange={(date) =>
                    this.handleLfdcFormContentFill("startDate", date)
                }
              />
            </MuiPickersUtilsProvider>
            <MuiPickersUtilsProvider
              utils={DateFnsUtils}
              locale={this.props.lang === LANG.RU ? ruLocale : enLocale}
            >
              <KeyboardDateTimePicker
                inputVariant="outlined"
                id="endDatePicker"
                ampm={false}
                className={classes.dateTextField}
                disableFuture={true}
                style={{ margin: 0 }}
                minutesStep={5}
                size="small"
                margin="normal"
                label={t("datasets.end_date")}
                okLabel={t("common.ok")}
                cancelLabel={t("common.cancel")}
                format="yyyy-MM-dd HH:mm"
                value={this.state.lfdcFormContent?.endDate || new Date()}
                onChange={(date) =>
                    this.handleLfdcFormContentFill("endDate", date)
                }
              />
            </MuiPickersUtilsProvider>
          </>) : this.state.lfdcFormContent.date_type == "relative" ? (<>
            <NumberField
              id="quantity"
              label={t("datasets.amount")}
              className={classes.dateTextField}
              style={{ marginRight: 10 }}
              value={this.state.lfdcFormContent?.quantity || ''}
              inputProps={{min: 1}}
              onChange={event => this.handleLfdcFormContentFill("quantity", event.target.value)}
            />
            <FormControl className={classes.dateTextField} size="small" variant='outlined'>
              <InputLabel id={`${t("datasets.period")}_label`}>{t("datasets.period")}</InputLabel>
              <Select
                label={t("datasets.period")}
                labelId="datasets_period_label"
                id="period"
                variant="outlined"
                value={this.state.lfdcFormContent?.period || ""}
                onChange={event => {
                  this.handleLfdcFormContentFill("period", event.target.value);
                }}
              >
                {["day", "week", "month", "year"].map(
                  (item, idx) => <MenuItem style={{ fontSize: "13px" }} key={`${item}_${Math.random()}`}
                    value={item}>{t('datasets.period_'+ item)}</MenuItem>)}
              </Select>
            </FormControl>
          </>) : null}
        </FormGroup>
        <FormGroup className={classes.editLoadFromDCParamsRow}>
          <NumberField
            label={t("datasets.max_size")}
            className={classes.smallTextField}
            style={{ marginLeft: 0 }}
            value={this.state.lfdcFormContent?.maxSize}
            inputProps={{min: 1}}
            onChange={event => this.handleLfdcFormContentFill("maxSize", event.target.value)}
          />
        </FormGroup>
        {0 ? <FormGroup className={classes.editLoadFromDCParamsRow}>
          <InputLabel id={"role-select"}>{t("datasets.role")}</InputLabel>
          <Select
            labelId="role-select"
            label={t("datasets.role")}
            value={this.state.lfdcFormContent?.role}
            onChange={(event) => this.handleLfdcFormContentFill("role", event.target.value)}
          >
            <MenuItem value={"user"}>User</MenuItem>
            <MenuItem value={"bot"}>Bot</MenuItem>
          </Select>
        </FormGroup> : null}
        <FormGroup className={classes.editLoadFromDCParamsRow}>
          <FormControlLabel
            className={classes.checkboxStart}
            control={<Checkbox/>}
            label={t("datasets.intent_slot")}
            checked={this.state.intentSlotChecked}
            onChange={(event) => {
              const check = event.target.checked;
              this.setState((prevState) => ({
                                            intentSlotChecked: check,
                                            lfdcFormContent: {
                                              ...prevState.lfdcFormContent,
                                              intent_slot: check ? "dummy" : '',
                                            },
              }));
            }}
          />
          {this.state.intentSlotChecked ?
            <TextField
              required
              className={classes.smallTextField}
              style={{marginTop: 0}}
              defaultValue={this.state.lfdcFormContent?.intent_slot || ""}
              size="small"
              onChange={(event) => {
                this.handleLfdcFormContentFill("intent_slot", event.target.value);
              }}
              type="string"
              variant="outlined"
              fullWidth
            /> : null}
        </FormGroup>
        <FormGroup className={classes.editLoadFromDCParamsRow}>
          <FormControlLabel
            className={classes.checkboxStart}
            control={<Checkbox/>}
            label={t("datasets.update_intents")}
            checked={this.state.lfdcFormContent?.update_intents}
            onChange={(event) =>
                this.handleLfdcFormContentFill("update_intents", event.target.checked)
            }
          />
        </FormGroup>
        <FormGroup className={classes.editLoadFromDCParamsRow}>
          <FormControlLabel
            className={classes.checkboxStart}
            control={<Checkbox/>}
            label={t("datasets.check_audio")}
            checked={this.state.lfdcFormContent?.check_audio}
            onChange={(event) =>
                this.handleLfdcFormContentFill("check_audio", event.target.checked)
            }
          />
        </FormGroup>
        <FormGroup className={classes.editLoadFromDCParamsRow}>
          <FormControlLabel
            className={classes.checkboxStart}
            control={<Checkbox/>}
            label={t("datasets.sanitize")}
            checked={this.state.lfdcFormContent?.sanitize}
            onChange={(event) =>
                this.handleLfdcFormContentFill("sanitize", event.target.checked)
            }
          />
        </FormGroup>
        <FormGroup className={classes.editLoadFromDCParamsRow}>
          <FormControlLabel
            className={classes.checkboxStart}
            control={<Checkbox/>}
            label={t("datasets.ani")}
            checked={this.state.aniChecked}
            onChange={(event) => {
              this.setState({ aniChecked: event.target.checked });
              this.handleLfdcFormContentFill("ani", '');
            }}
          />
          {this.state.aniChecked ? <TextField
            className={classes.smallTextField}
            style={{marginTop: 0}}
            defaultValue={this.state.lfdcFormContent?.ani}
            size="small"
            onChange={(event) =>
                this.handleLfdcFormContentFill("ani", event.target.value)
            }
            type="string"
            variant="outlined"
            fullWidth
          /> : null}
        </FormGroup>
        <FormGroup className={classes.editLoadFromDCParamsRow}>
          <FormControlLabel
            className={classes.checkboxStart}
            control={<Checkbox/>}
            label={t("datasets.channel")}
            checked={this.state.channelChecked}
            onChange={(event) => {
              this.setState({ channelChecked: event.target.checked });
              this.handleLfdcFormContentFill("channel", '');
            }}
          />
          {this.state.channelChecked ?
            <TextField
              className={classes.smallTextField}
              style={{marginTop: 0}}
              defaultValue={this.state.lfdcFormContent?.channel}
              size="small"
              onChange={(event) =>
                  this.handleLfdcFormContentFill("channel", event.target.value)
              }
              type="string"
              variant="outlined"
              fullWidth
            /> : null}
        </FormGroup>
        <FormGroup className={classes.editLoadFromDCParamsRow} style={{justifyContent: 'space-between'}}>
          <RadioGroup
            labels={['first','all'].reduce((a,k) => {a[k]=t('datasets.utterance_'+k); return a}, {})}
            value={this.state.lfdcFormContent?.first ? "first" : "all"}
            onChange={event => this.handleLfdcFormContentFill("first", event.target.value == "first")}
            legend={{name: t("datasets.utterances"), classes: classes.checkboxLabelBeforeRadio}}
          />
          <FormControlLabel
            control={<Checkbox/>}
            label={t(`datasets.glue_utterances`)}
            checked={this.state.lfdcFormContent?.glue}
            onChange={event => this.handleLfdcFormContentFill("glue", event.target.checked)}
          />
        </FormGroup>
        <FormGroup className={classes.editLoadFromDCParamsRow}>
          <RadioGroup
            labels={['none','auto','manual'].reduce((a,k) => {a[k]=t('datasets.'+k); return a}, {})}
            value={this.state.lfdcFormContent?.status}
            onChange={event => this.handleLfdcFormContentFill("status", event.target.value)}
            legend={{name: t("datasets.annotation"), classes: classes.checkboxLabelBeforeRadio}}
          />
        </FormGroup>
        <Button
          style={{marginTop: 13, alignSelf: 'start' }}
          variant="outlined"
          onClick={() => this.handleOpenLoadConfig(true)}
        >
          {t("datasets.load_config")}
        </Button>
      </FormControl>
    </>);
  };

  handleExportMenuClose = (id, name) => fmt => {
    if (fmt)
      this.handleDownloadFile(id, name, fmt);
  };

  render() {
    const { classes, t, projectDatasets } = this.props;

    const {
      showModal,
      modalContent,
      showConfirmStatus,
      editDialogOpen,
      radioValueFileType,
      showBtnNameAgree,
      maxWidth,
      rowId,
      dataSetName,
      uploadProperties,
      copyProps,
    } = this.state;

    const getModalStatusContent = () => {
      const row = projectDatasets.find(d => d._id == rowId);
      if (!row) return null;
      const getErrorMessage = e => decodeMessage(e, this.props.t, 'datasets') || JSON.stringify(e);
      // TODO: common status dialog (see also ModelStatusDialog, modalContentStatus (tests and autoAnnotation))
      const show_error = s => s?.error ? (
        <tr style={{ color: "red" }}>
          <td>{t("common.error")}</td>
          <td>{getErrorMessage(s.error.error || s.error)}</td>
        </tr>
      ) : null;
      const show_progress = p => p ? (
        <>
          <tr><td>{t("progress.items")}</td><td>{p.current} {t("progress.items_of")} {p.total}</td></tr>
          <tr><td>{t("progress.percent")}</td><td>{p.percent}%</td></tr>
          <tr><td>{t("progress.time_elapsed")}, {t('common.seconds_a')}</td><td>{p.time_total}</td></tr>
          <tr><td>{t("progress.time_left")}, {t('common.seconds_a')}</td><td>{p.est_time_left}</td></tr>
        </>
      ) : null;
      const s = row.execution;
      const p = s?.progress;
      return <table style={{width: '100%'}}><tbody>
        {show_error(s)}
        {['upload', 'import_from_dc'].includes(s.task_name) ? (
          <>
            <tr>
              <td>{t("datasets.load_start")}</td>
              <td><span style={{ textDecoration: "underline" }}>{showDate(s?.started)}</span></td>
            </tr>
            {s.status == 'Loading...' ? show_progress(s?.progress) : null}
            <tr>
              <td>{t(s.status != "Aborted" ? "datasets.load_end" : "datasets.load_abort")}</td>
              <td><span style={{ textDecoration: "underline" }}>
                {!this.isDatasetBeingProcessed(row)? showDate(s.finished) : ''}
              </span></td>
            </tr>
          </>
        ) : s.task_name == 'vectorize' ? (
          <>
            <tr>
              <td>{t("datasets.vecn_start")}</td>
              <td><span style={{ textDecoration: "underline" }}>{showDate(s.started)}</span></td>
            </tr>
            {s.status == 'Vectorizing...' && p && p.queued === undefined ? show_progress(p) : null}
            <tr>
              <td>{t(s.status != "Aborted" ? "datasets.vecn_end" : "datasets.vecn_abort")}</td>
              <td><span style={{ textDecoration: "underline" }}>
                {!this.isDatasetBeingProcessed(row) && p?.queued === undefined ? showDate(s.finished) : ''}
              </span></td>
            </tr>
          </>
        ) : (
          <tr><td>error: unknown_task!!</td></tr>
        )}
      </tbody></table>;
    }

    const headCells = [
      {
        _id: "name",
        label: t("common.name"),
        width: "20%",
        textSearch: true
      },
      {
        _id: "description",
        label: t("common.description"),
        width: "20%",
        textSearch: true,
        optional: true,
      },
      {
        _id: "size",
        label: t("datasets.num_rows_total"),
      },
      {
        _id: "manual",
        label: t("datasets.num_rows_manual"),
      },
      {
        _id: "auto",
        label: t("datasets.num_rows_auto"),
      },
      {
        _id: "updatedAt",
        label: t("datasets.date"),
        width: "20%",
        align: "left",
        dateTime: true,
      },
      {
        _id: "datasetStatus",
        label: t("common.status"),
        width: "10%",
        link: row => {
          this.setState({ showConfirmStatus: true, rowId: row._id });
        },
        style: linkStyle(row => [row.execution, this.isDatasetBeingProcessed(row)],
                         { Loaded: 'green', Vectorized: 'green' }),
      },
    ];

    const is_from_file = this.state.tabValue == 0;
    const is_from_dc = this.state.tabValue == 1;
    const is_copy = this.state.tabValue == 2;
    
    return (
      <div className={classes.root}>
        {showModal && (
          <ConfirmDialog
            maxWidth={maxWidth || false}
            title={t("datasets.confirm_title")}
            open={showModal}
            showBtnNameAgree={showBtnNameAgree}
            content={modalContent}
            closeModal={this.handleCloseDeleteModal}
          />
        )}
        {showConfirmStatus && (
          <ConfirmDialog
            open={true}
            content={getModalStatusContent()}
            showBtnNameAgree={false}
            title={t("common.info")}
            btnNameDisagree={t("common.close")}
            closeModal={() => this.setState({ showConfirmStatus: false })}
          />
        )}
        <Dialog
          maxWidth="sm"
          classes={{ paper: classes.dialogPaper }}
          fullWidth={true}
          scroll="paper"
          open={editDialogOpen}
          onClose={notBackdropClicked(() => this.handleClose(false))}
          aria-labelledby="dialog-upload-title"
        >
          <CloseBar
            onClose={() => this.handleClose(false)}
            title={rowId ? t("datasets.dataset_edit") : t("datasets.dataset_upload")}
          />
          <DialogContent dividers={true}>
            <TextField
              required
              size="small"
              variant="outlined"
              fullWidth
              style={{ margin: "8px 0" }}
              id="dataSetName"
              label={t("common.name")}
              defaultValue={this.state.dataSetName}
              onChange={event => this.setState({ dataSetName: event.target.value })}
            />
            <TextField
              size="small"
              variant="outlined"
              fullWidth
              style={{ margin: "8px 0" }}
              id="description"
              label={t("common.description")}
              multiline
              rows="3"
              className={classes.textField}
              defaultValue={this.state.description}
              onChange={event => this.setState({ description: event.target.value })}
            />
            <FormControlLabel
              style={{ marginLeft: -3, height: "auto" }}
              control={<Checkbox/>}
              disabled={Boolean(rowId)}
              label={t("datasets.unique")}
              checked={this.state.unique}
              onChange={(event) =>
                this.setState({ unique: event.target.checked })
              }
            />
            <Toolbar disableGutters>
              <Tabs
                centered
                className={classes.tabs}
                value={this.state.tabValue}
                onChange={this.handleTabChange}
              >
                <Tab label={t("datasets.upload_tab")} {...a11yProps(0)} />
                <Tab label={t("datasets.load_tab")} {...a11yProps(1)} />
                {getRunConfigOption('copyDatasetTab') && rowId
                ? <Tab label={t("datasets.copy_tab")} {...a11yProps(2)} /> : null}
              </Tabs>
            </Toolbar>
            <TabPanel
              value={this.state.tabValue}
              visible={true}
              index={0}
              style={{ border: "#C4C4C4 solid 1px" }}
            >
              <div style={{ height: radioValueFileType == "json" ? 255 : 415, overflowY: "auto" }}>
                <div style={{ marginBottom: 20, width: "100%", display: "flex" }}>
                  <RadioGroup
                    style={{ marginLeft: -16, width: "100%" }}
                    name="position"
                    labels={{json: "JSON", csv: "CSV"}}
                    defaultValue="json"
                    value={radioValueFileType}
                    onChange={this.handleChangeSwitch}
                  />
                </div>
                <div style={{ marginBottom: 20, textAlign: "left" }}>
                  <Upload
                    btnLabel={t('common.choose_file')}
                    onFileLoad={this.onFileLoad.bind(this)}
                  />
                </div>
                <div style={{ marginTop: 10 }}>
                  <RadioGroup
                    name="annotation"
                    labels={['none','auto','manual'].reduce((a,k) => {a[k]=t('datasets.'+k); return a}, {})}
                    value={this.state.annStatus}
                    onChange={event => this.setState({ annStatus: event.target.value })}
                    legend={{name: t("datasets.annotation"), classes: classes.checkboxLabelBeforeRadio}}
                  />
                  {radioValueFileType === "csv" && (
                    <>
                      <div style={{ display: "flex" }}>
                        <FormLabel
                          className={classes.checkboxLabelBeforeText}
                          component="legend"
                        >
                          {t("datasets.separator")} *
                        </FormLabel>
                        <div>
                          <div style={{ display: "flex" }}>
                            <TextField
                              required
                              size="small"
                              className={classes.smallTextField}
                              id="csvSeparator"
                              onChange={(event) =>
                                  this.setState({ csvSeparator: event.target.value })
                              }
                              variant="outlined"
                              fullWidth
                              defaultValue={this.state.csvSeparator}
                            />
                          </div>
                        </div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <FormLabel
                          className={classes.checkboxLabelBeforeText}
                          component="legend"
                        >
                          {t("datasets.fields")}
                        </FormLabel>
                        <div>{(() => {
                          const FIELDS = {
                            utterance:  'csvUtterance',
                            intent:     'csvIntentField',
                            confidence: 'csvConfidence',
                            audio:      'csvAudio'
                          };
                          return (
                            <div style={{ display: "flex" }}>
                              {Object.entries(FIELDS).map(([name, field]) => (
                                <TextField
                                  key={name}
                                  required={name == 'utterance'}
                                  size="small"
                                  className={classes.smallTextField}
                                  id={field}
                                  label={t("datasets."+ name)}
                                  onChange={event => this.setState({ [field]: event.target.value })}
                                  variant="outlined"
                                  fullWidth
                                  defaultValue={this.state[field]}
                                />))}
                            </div>
                          )})()}
                        </div>
                      </div>
                      <FormControlLabel
                        style={{ marginTop: 13, height: "auto" }}
                        control={
                          <Checkbox
                            checked={this.state.header}
                            onChange={() =>
                                this.setState((prevState) => ({
                                  header: !prevState.header,
                                }))
                            }
                          />
                        }
                        label={t("datasets.fist_row_is_header")}
                      />
                    </>
                  )}
                </div>
                <div style={{ marginTop: 10 }}>
                  <FormControl component="fieldset">
                    <FormGroup>
                      {Object.keys(uploadProperties)
                        .map(name => (
                          <FormControlLabel
                            key={name}
                            control={
                              <Checkbox
                                checked={uploadProperties[name]}
                                onChange={this.handleUploadPropertiesChange}
                                name={name}
                              />
                            }
                            label={t("datasets."+ name)}
                          />
                        ))}
                    </FormGroup>
                  </FormControl>
                </div>
              </div>
            </TabPanel>
            <TabPanel
              value={this.state.tabValue}
              visible={true}
              index={1}
              style={{ border: "#C4C4C4 solid 1px" }}
            >
              <div style={{ height: 415, overflowY: "auto" }}>
                {this.loadFromDialogComposerForm(t, classes)}
              </div>
            </TabPanel>
            {getRunConfigOption('copyDatasetTab') && rowId ? 
            <TabPanel
              value={this.state.tabValue}
              visible={true}
              index={2}
              style={{ border: "#C4C4C4 solid 1px" }}
            >
              {Object.keys(copyProps)
                .map(name => (
                  <FormControlLabel
                    key={name}
                    control={
                      <Checkbox
                        checked={copyProps[name]}
                        onChange={this.handleCopyPropertiesChange}
                        name={name}
                      />
                    }
                    label={t("datasets."+ name)}
                  />
                ))}
              <FormControl component="fieldset">
                <FormGroup>
                  {Object.keys(this.state.copySlice)
                    .map(name => (
                      <NumberField
                        key={name}
                        label={t("datasets.slice_"+ name)}
                        className={classes.smallTextField}
                        style={{ marginTop: 10, width: '100%' }}
                        value={this.state.copySlice[name]}
                        inputProps={{min: 0}}
                        onChange={event => this.handleCopySliceFill(name, event.target.value)}
                      />
                    ))}
                </FormGroup>
              </FormControl>
            </TabPanel> : null}
            {getRunConfigOption('globalSettings') &&
            <fieldset style={{margin: '20px 0', borderWidth: 1, padding: 0}}>
              <legend style={{color: '#aaa', margin: '0 10px', padding: 0}}>{t('settings.vecn_sim')}</legend>
              <Box p={3}>
                <NLU_NB_SETTINGS
                  settings={this.state.similarityConfig}
                  setSettings={(name, opts = {}) => data => {
                    const value_obj = opts.is_direct ? data : { [name]: data.target.value };
                    this.setState({ ...this.state, similarityConfig: { ...this.state.similarityConfig, ...value_obj }})
                  }}
                  defaults={this.state.configDefaults?.similarityConfig}
                />
              </Box>
            </fieldset>
            }
          </DialogContent>
          <DialogActions>
            {is_from_dc && rowId
              ? <div><Button onClick={() => this.handleClose(true, "save_import_settings")} color="primary">
                {t("datasets.save_import_settings")}
              </Button></div> : null
            }
            <div style={{ marginLeft: "auto" }}>
              <Button onClick={() => this.handleClose(false)}                   color="primary">
                {t("common.cancel")}
              </Button>
              {is_from_file && !this.state.fileName || is_copy
                ? (
                  <Button onClick={() => this.handleClose(true, "save")}
                    disabled={!dataSetName && !is_copy}                 color="primary"
                  >
                    {t("common.save")}
                  </Button>
                ) : null
              }
              {is_from_file && this.state.fileName || is_from_dc
                ? (
                  <Button
                    disabled={is_from_dc && !dataSetName
                      || is_from_file && radioValueFileType == "csv"
                        && ['csvSeparator','csvUtterance'].some(k => !this.state[k])}
                    onClick={() => this.handleClose(true, "load")}                color="primary"
                  >
                    {t("common.load")}
                  </Button>
                ) : null
              }
            </div>
          </DialogActions>
        </Dialog>
        {projectDatasets && (
          <EnhancedTable
            passedPage={true}
            id="datasets"
            headCells={headCells}
            rows={projectDatasets.map(ds => ds.execution ? { ...ds, datasetStatus: ds.execution?.status } : ds)
                .map(ds => { if (!ds.stat) return ds; const { updatedAt, ...ds_stat } = ds.stat; return { ...ds, ...ds_stat } })}
            viewCell={{ datasetStatus: viewDatasetStatus, size: viewSize, manual: viewStat, auto: viewStat }}
            toolBarName={t("datasets.datasets")}
            newRowTitle={t("datasets.new")}
            rowsOnPage={setDefaultRowsOnPage(projectDatasets?.length)}
            handleClickNewRow={() => this.handleClickNewRow(null)}
            handleClickCheckBox={() => this.handleClickCheckBox}
            handleClickUpdateRow={this.fetchProjectDatasets}
            handleRowItemChanged={this.handleRowItemChanged}
            checkBoxTableCell={(id, name) => (
              <TableCellRadioButton id={id} name={name}/>
            )}
            customBtns={(name, id) => {
              const is_active = this.isActive(projectDatasets, id);
              const is_status_running = this.isStatusRunning(projectDatasets, id);
              const export_choices = Object.fromEntries(['JSON', 'CSV'].map(k => [FILE_FORMATS[k],k]));
              return (
                <div style={{width: "25%", display: "flex"}}>
                  <IconButton
                    title={t(is_active ? "common.stop" : "datasets.vectorize")}
                    style={is_active ? {} : {transform: 'rotate(180deg)'}}
                    variant="outlined"
                    className={classes.button}
                    disabled={is_active && !is_status_running}
                    onClick={() => this.vectorize(id, is_active)}
                    Icon={is_active ? BlockIcon : DeviceHubIcon}
                  />
                  <IconButton
                    className={classes.button}
                    title={t("common.edit")}
                    onClick={() => this.handleClickNewRow(id)}
                    Icon={EditIcon}
                  />
                  <SimpleMenu
                    Icon={GetAppIcon}
                    choices={export_choices}
                    title={t("common.export")}
                    handleClose={this.handleExportMenuClose(id, name)}
                  />
                  <IconButton
                    title={t("common.delete")}
                    onClick={() => this.handleDeleteRow(id, name)}
                    Icon={DeleteIcon}
                    style={{marginLeft: 10}}
                  />
                </div>
              )
            }}
          />
        )}
      </div>
    );
  }
}

function TableCellRadioButton(props) {
  const selectedDataSetId = useSelector(getSelectedDataSetId);
  const dispatch = useDispatch();
  const {id} = props;

  useEffect(() => {
    dispatch(setSelectedDataSetId());
  });

  return (
    <TableCell padding="checkbox">
      <Radio
        checked={id === selectedDataSetId}
        onChange={() => changeSelectedDataSetId(dispatch, id)}
      />
    </TableCell>
  );
}

const mapStateToProps = (state) => ({
  projects: state.settings.projects,
  projectId: state.settings.projectInfo.projectId,
  projectComposerConfig: state.settings.projectInfo.composerConfig,
  lang: state.settings.lang,
  projectDatasets: state.settings.projectDatasets,
  selectedDataSetId: state.settings.selectedDataSetId,
  userName: state.settings.userName,
});

export default withRouter(connect(mapStateToProps)(withStyles(styles)(withTranslation()(Datasets))),);
