import React, {
  useState, useEffect,
}                               from "react";
import { useTranslation }       from 'react-i18next';
import { useDispatch }          from "react-redux";

import {
  DIALOG_USER_STATE, NLU_TYPES,
}                               from '../../../core/constants';

import { Grid }                 from "@material-ui/core";
import List                     from "@material-ui/core/List";
import ListItem                 from "@mui/material/ListItem";
import ListItemText             from "@material-ui/core/ListItemText";
import Tabs                     from "@material-ui/core/Tabs";
import Tab                      from "@material-ui/core/Tab";
import Toolbar                  from "@material-ui/core/Toolbar";
import Box                      from "@material-ui/core/Box";
import Typography               from "@material-ui/core/Typography";
import GridList                 from "@material-ui/core/GridList";
import GridListTile             from "@material-ui/core/GridListTile";
import TextField                from "@material-ui/core/TextField";
import Autocomplete             from "@material-ui/lab/Autocomplete";

import { makeStyles }           from "@material-ui/core/styles";

import NumberField              from "../../components/material-ui/NumberField";
import ConfirmDialog            from "../../components/confirmDialog";

import {
  getDataNew,
  postDataNew,
  putDataNew,
}                               from "../../../core/fetchService";
import { cmpPossibleNumbers }   from "../../../core/utils";

const EMPTY_SIM_CONFIG_DETAILS = { models: {} };


const useStyles = makeStyles(theme => ({
  list: {
    width: '100%',
  },
  selected: {
    // '!important' to override styles coming with 'button' property
    backgroundColor: theme.palette.action.hover +' !important',
  },
  gridList: {
    overflow: "hidden",
  },
  textField: {
    marginTop: 8,
  },
  dialogContent: {
    padding: '0 10px',
  },
  fieldset: { // from editModelDialog.js
    height: 400,
    overflow: 'auto',
    padding: '5px 25px',
    borderRadius: 5,
    borderWidth: 1,
  },
  legend: {
    marginLeft: '-10px',
    color: "#aaa",
  },
}));


export default function SettingsDialog({ onClose }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();

  const [settings, setSettings] = useState({});

  useEffect(() => {
    getDataNew('/api/settings', dispatch, data => setSettings(data.settings[0] || {}));
  }, []);

  const handleClose = state => {
    if (state == DIALOG_USER_STATE.AGREE) {
      const url = '/api/settings'+ (settings._id ? '/'+ settings._id : '');
      const saveData = settings._id ? putDataNew : postDataNew;
      saveData(url, { settings }, dispatch);
    }
    onClose();
  };

  return (
    <ConfirmDialog
      title={t("common.settings")}
      content={<SettingsMenu settings={settings} setSettings={setSettings}/>}
      closeModal={handleClose}
      fullWidth
      maxWidth='md'
      contentProps={{
        className: classes.dialogContent,
      }}
    />
  );
}

export function SettingsMenu({ settings, setSettings, similarityConfigDetails, defaults = {} }) {
  const { t } = useTranslation();
  const classes = useStyles();

  const MENU = {
    backends: {
      component: BackendSettings,
      props: {
        settings,
        setSettings,
        similarityConfigDetails,
        defaults,
      }
    },
    import_from_dc: {
      component: ImportFromDCSettings,
      props: {
        id: 'import',
        fields: ['url', 'applicationId', 'token'],
        settings: settings.composerConfig,
        setSettings: n => e => setSettings({
          ...settings,
          composerConfig: { ...settings.composerConfig, [n]: e.target.value },
        }),
        defaults: defaults.composerConfig,
      },
    },
  };

  const [menuItemKey, setMenuItemKey] = useState('backends');

  return (
    <Grid container wrap='nowrap' style={{ overflow: 'auto' }}>
      <Grid item xs={3} sm={3} style={{ padding: 0, borderRight: 'solid 1px #eee' }}>
        <List className={classes.list}>
          {Object.keys(MENU).map((k,i,a) => (
            <ListItem key={k} button
              className={k == menuItemKey ? classes.selected : ''}
              style={i ? { borderTop: 'none' } : {}}
              onClick={() => setMenuItemKey(k)}
            >
              <ListItemText primary={t('settings.menu_'+ k)}/>
            </ListItem>
          ))}
        </List>
      </Grid>
      <Grid item xs={9} sm={9} style={{ padding: 10 }}>
        {Object.keys(MENU).map(k => {
          const MenuItem = MENU[k].component;
          return (
            <fieldset key={k} className={classes.fieldset} hidden={k != menuItemKey}>
              <legend className={classes.legend}>{t('settings.menu_'+ k)}</legend>
              <MenuItem {...MENU[k].props}/>
            </fieldset>
          )})}
      </Grid>
    </Grid>
  );
}

const TabPanel = (props) => {
  const { children, value, index, visible, ...other } = props;
  return (
    <div
      {...other}
      role="tabpanel"
      hidden={value !== index || !visible}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

function BackendSettings({ fields, settings = {}, setSettings, similarityConfigDetails, defaults = {} }) {
  const [tab, setTab] = useState(0);

  const { t } = useTranslation();
  const classes = useStyles();

  const BESettingsTabs = {
    NLU_NB:   {
      key: 'similarityConfig',
      component: NLU_NB_Settings,   props: { similarityConfigDetails } },
    NLU_RASA: {
      key: 'nluConfig',
      component: NLU_RASA_Settings, props: { id: 'nlu', fields: ['train_url', 'deploy_url', 'model_server_url'] },
    },
    ASR_E2E:  {
      key: 'asrConfig',
      component: ASR_E2E_Settings,  props: { id: 'asr', fields: ['train_url'] },
    },
  };

  const setTypeSettings = type => (name, opts = {}) => data => {
    const value_obj = opts.is_direct ? data : { [name]: data.target.value };
    const key = BESettingsTabs[type].key;
    setSettings({ ...settings, [key]: { ...settings[key], ...value_obj } });
  };

  return (<>
    <Toolbar disableGutters style={{ minHeight: 48 }}>
      <Tabs centered value={tab} onChange={(e,t) => setTab(t)} style={{paddingBottom: 5}}>
        {Object.keys(NLU_TYPES).map(k => (
          <Tab key={k} label={t('train.type_'+ k.toLowerCase())}/>
        ))}
      </Tabs>
    </Toolbar>
    {Object.keys(NLU_TYPES).map((type,i) => {
      const BESettingsTab = BESettingsTabs[type].component;
      return (
        <TabPanel key={type} value={tab} visible={true} index={i}>
          <BESettingsTab
            {...BESettingsTabs[type].props}
            settings={settings[BESettingsTabs[type].key]}
            setSettings={setTypeSettings(type)}
            defaults={defaults[BESettingsTabs[type].key]}
          />
        </TabPanel>
      )})}
  </>)
}

export function FieldSetSettings({ id, fields, settings = {}, setSettings, defaults = {} }) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <GridList className={classes.gridList} cols={1}>
      {fields.map(name => (
        <GridListTile key={name} cols={1} style={{ height: "auto" }}>
          <TextField
            label={t(`settings.${id}_${name}`)}
            size="small"
            variant="outlined"
            fullWidth
            className={classes.textField}
            placeholder={defaults[name]}
            value={settings?.[name] || ''}
            onChange={setSettings(name)}
          />
        </GridListTile>
      ))}
    </GridList>
  );
}

export function NLU_NB_Settings({ settings = {}, setSettings, similarityConfigDetails, defaults = {} }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [savedValue, setSavedValue] = useState();
  const [simConfDetails, setSimConfDefails] = useState(similarityConfigDetails || EMPTY_SIM_CONFIG_DETAILS);

  const updateSimConfDetails = async server_url => {
    let d = EMPTY_SIM_CONFIG_DETAILS;
    if (server_url) {
      const url = `/api/settings/similarity_config_details?server=${server_url}`;
      await getDataNew(url, dispatch, data => {
        d = data.similarity_config_details || EMPTY_SIM_CONFIG_DETAILS;
      });
    }
    // in case of request error this resets simConfDetails
    setSimConfDefails(d);
  };

  useEffect(() => {
    !similarityConfigDetails && updateSimConfDetails(settings.server);
  }, []);

  const handleSimilarityConfigServerFocus = event => setSavedValue(event.target.value);
  const handleSimilarityConfigServerChange = async event => {
    const value = event.target.value;
    if (value && value != savedValue)
      updateSimConfDetails(value);
  };

  const onChange = name => (event, value) => {
    const other = name == 'model' ? { partition: null }
      : name == 'server' ? { model: null, partition: null }
      : {};
    if (name == 'server')
      setSimConfDefails(EMPTY_SIM_CONFIG_DETAILS);
    setSettings(name, { is_direct: true })({ [name]: value, ...other });
  };

  return (
    <GridList className={classes.gridList} cols={1}>
      <GridListTile cols={1} style={{ height: "auto" }}>
        <TextField
          size="small"
          className={classes.textField}
          variant="outlined"
          label={t("projects.similarity_server")}
          fullWidth
          defaultValue={settings?.server || ""}
          placeholder={defaults.server}
          onFocus={handleSimilarityConfigServerFocus}
          onChange={setSettings("server")}
          onBlur={handleSimilarityConfigServerChange}
        />
      </GridListTile>
      <GridListTile cols={1} style={{ height: "auto" }}>
        <Autocomplete
          size="small"
          fullWidth
          className={classes.textField}
          options={Object.keys(simConfDetails.models)}
          getOptionLabel={m => {
            const cps = simConfDetails.models[m]?.cluster_partitions;
            return cps && cps.length ? m +` _ _ _ _ _ [${t("projects.cluster_partitions")}: `
              + cps.join(', ') +']' : m;
          }}
          onChange={onChange("model")}
          defaultValue={settings?.model}
          renderInput={params =>
            <TextField {...params} variant="outlined" label={t("projects.model")} placeholder={defaults.model}/>
          }
        />
      </GridListTile>
      <GridListTile cols={1} style={{ height: "auto" }}>
        <Autocomplete
          size="small"
          fullWidth
          options={
            (settings?.model
              && simConfDetails.models[settings?.model]?.cluster_partitions || [])
              .sort(cmpPossibleNumbers)
          }
          className={classes.textField}
          defaultValue={settings?.partition ? String(settings.partition) : undefined}
          renderInput={params =>
            <TextField
              {...params} variant="outlined" label={t("projects.cluster_partitions")} placeholder={defaults.partition}
            />}
          onChange={onChange("partition")}
        />
      </GridListTile>
      <GridListTile cols={1} style={{ height: "auto" }}>
        <NumberField
          label={t("settings.sim_limit")}
          className={classes.textField}
          value={settings?.max || ''}
          inputProps={{ min: 0 }}
          placeholder={String(defaults.max || '')}
          onChange={setSettings("max")}
        />
      </GridListTile>
      <GridListTile cols={1} style={{ height: "auto" }}>
        <NumberField
          label={t("settings.sim_threshold")}
          className={classes.textField}
          value={settings?.threshold || ''}
          inputProps={{ min: 0, max: 1, step: '.1' }}
          placeholder={String(defaults.threshold || '')}
          onChange={setSettings("threshold")}
        />
      </GridListTile>
    </GridList>
  );
}

const NLU_RASA_Settings = FieldSetSettings;
const ASR_E2E_Settings = FieldSetSettings;
const ImportFromDCSettings = FieldSetSettings;

