import React, { useEffect, useRef } from "react";
import { Switch, Link, Router, Route, Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Box from "@material-ui/core/Box";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import LinearProgress from "@material-ui/core/LinearProgress";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SettingsIcon from "@material-ui/icons/SettingsOutlined";

import clsx from "clsx";

import SnackbarMessage    from "./snackbarMessage";
import LanguageSelect     from "./selectLang";
import InfoPopover        from "./infoPopover";
import SimpleBackdrop     from "./backDrop";
import SettingsDialog     from "./SettingsDialog";

import history                                          from "../../../other/history";

import {
  fetchProjects,
  getProjects,
  getIsLoading,
  getProjectDatasets,
  getSelectedDataSetId,
  setSelectedDataSetId,
  getLocationPath,
  fetchAllSlots,
  fetchModelConfigList,
  fetchTestModelList,
  fetchAutoModelList,
  dispatchChainingComboProjectDataset,
  changeSelectedDataSetId,
  setToken,
  getToken,
  getLang,
  getOauthServer,
}                                                       from "../../../features/settings";
import { fetchIntents }                                 from "../../../features/annotationSettings";
import { fetchAllEntities }                             from "../../../features/lookup";
import { setSelectedModelId, fetchModelList }           from "../../../features/model";
import {
  Users,
  UserGroups,
  Projects,
  Datasets,
  Intents,
  Slots,
  Entities,
  Filters,
  Annotation,
  TrainConfigs,
  Train,
  Tests,
  AutoAnnotation,
  Batches,
  SignIn,
}                                                       from "../../pages";
import { OAuthCallback }                                from "../../pages/signIn";

import {
  GetToken, GetProjectInfo, GetUserName,
  IsAdmin, getRunConfigOption,
}                                                       from "../../../core/utils";
import { SOME_SETTINGS }                                from "../../../core/constants";

const Routes = [
  { path:["/users/:user_id", "/users"],           Section:Users,      is_admin: true,   },
  { path:["/user_groups/:group_id",
          "/user_groups"],                        Section:UserGroups, is_admin: true,   },
  { path:["/projects/:project_id", "/projects"],  Section:Projects,       exact: true,  },
  { path:["/datasets/:project_id", "/datasets"],  Section:Datasets,                     },
  { path:["/intents/:project_id", "/intents"],    Section:Intents,                      },
  { path:"/entities",                             Section:Entities,                     },
  { path:["/annotation/:project_id/:dataset_id",
          "/annotation/:project_id",
          "/annotation"],                         Section:Annotation,                   },
  { path:["/filters/:project_id", "/filters"],    Section:Filters,                      },
  { path:["/configs/:project_id", "/configs"],    Section:TrainConfigs,                 },
  { path:["/train/:project_id", "/train"],        Section:Train,                        },
  { path:["/tests/:project_id", "/tests"],        Section:Tests,                        },
  { path:["/slots/:project_id", "/slots"],        Section:Slots,                        },
  { path:["/auto_annotation/:project_id",
          "/auto_annotation"],                    Section:AutoAnnotation,               },
  { path:["/batches/:project_id", "/batches"],    Section:Batches,                      },
];

const drawerWidth = 160;

const fixScroll = {
  main: {
    overflow: "hidden",
  },
  container: {
    height: "97%",
    overflow: 'auto',
  },
};

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuItemAdmin: {
    color: "blue",
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
    textAlign: "left",
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: 0,
    // width: theme.spacing(7),
    // [theme.breakpoints.up('sm')]: {
    //   width: theme.spacing(9),
    // },
  },
  appBarSpacer: theme.mixins.toolbar,
  main: {
    flexGrow: 1,
    height: "100vh",
    ...fixScroll.main,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    ...fixScroll.container,
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  option: {
    color: "black",
    minHeight: "auto",
    alignItems: "flex-start",
    padding: 8,
    "&[aria-selected=\"true\"]": {
      backgroundColor: "transparent",
    },
    "&[data-focus=\"true\"]": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  rootAuto: {
    display: "inline-block",
  },
  inputAuto: {
    color: "white",
    minWidth: "250px",
  },
  labelAuto: {
    color: "white",
  },
}));

function ProgressIndicator() {
  return useSelector(getIsLoading) ? <LinearProgress/> : <div style={{ height: 4 }}></div>
}

export default function Dashboard() {
  const dispatch = useDispatch();
  const lang = useSelector(getLang);
  const oauthServer = useSelector(getOauthServer);
  const selectedDataSetId = useSelector(getSelectedDataSetId);
  const projectDatasets = useSelector(getProjectDatasets);
  const locationPath = useSelector(getLocationPath);
  const projects = useSelector(getProjects);
  const { projectId, projectName } = GetProjectInfo();
  const tokenAfterLogon = useSelector(getToken);
  const isLoggedIn = tokenAfterLogon || Boolean(localStorage.getItem(SOME_SETTINGS.TOKEN));
  const classes = useStyles();
  const [open, setOpen] = React.useState(isLoggedIn);
  const [showSettings, setShowSettings] = React.useState();
  const _setOpen = (v) => { localStorage.setItem('menu_open', v); setOpen(v); }

  const createPathName = () => {
    const pathString = history.location.pathname;
    const pathName = pathString === "/" ? "projects"
      : pathString.slice(1, pathString.slice(1).indexOf("/") + 1);
    return pathName || (isLoggedIn ? "projects" : "sign-in");
  };

  const [currentPathname, setCurrentPathname] = React.useState(createPathName());

  const handleDrawerOpen =  () => { _setOpen(true) };
  const handleDrawerClose = () => { _setOpen(false) };

  const handleLogOut = () => {
    dispatch(setToken(null));
    localStorage.clear();
    localStorage.setItem('menu_open', open);
    history.push("/sign-in");
  };

  const { t } = useTranslation();

  useEffect(() => {
    setCurrentPathname(createPathName());

    const open = localStorage.getItem('menu_open');
    setOpen(isLoggedIn && (open == undefined || open == 'true'));
  }, [tokenAfterLogon, createPathName()]);

  useEffect(() => {
    if (isLoggedIn && !projects) {
      dispatch(fetchProjects());
    }
  }, [tokenAfterLogon]);

  const DataSetSelector = React.useCallback(() => {
    if (!projectDatasets?.length)
      return null;

    const onChange = (event, value) => {
      if (value) {
        changeSelectedDataSetId(dispatch, value._id);
        history.push(`/annotation/${projectId}/${value._id}`);
      }
    };

    let defaultValue = null;
    if (!selectedDataSetId) {
      defaultValue = projectDatasets[0];
      localStorage.setItem("selectedDataSetId", defaultValue?._id);
      dispatch(setSelectedDataSetId());
    } else {
      defaultValue = projectDatasets?.find(ds => ds._id == selectedDataSetId) || null;
    }

    return (
      <Typography component="div" variant="subtitle1" color="inherit"
                  style={{ display: "inline-block", marginLeft: "20px" }}>
        {t("dashboard.data_set")}:
        <Autocomplete
          size="small"
          style={{ marginLeft: "5px" }}
          classes={{
            paper: classes.paper,
            option: classes.option,
            inputRoot: classes.inputAuto,
            root: classes.rootAuto,
            popupIndicator: classes.labelAuto,
            clearIndicator: classes.labelAuto,
          }}
          disableClearable
          options={projectDatasets}
          getOptionLabel={(option) => option.name}
          value={defaultValue}
          onChange={onChange}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField style={{ marginTop: "1px" }}
                       {...params}
                       size="small"
            />
          )}
        />
      </Typography>
    );
  }, [selectedDataSetId, projectDatasets]);

  const isLocationPath = p => currentPathname == p;
  const isListItemSelected = isLocationPath;

  function ProjectAndDatasetSelector() {
    let ps;
    return (
      <Typography component="h3" variant="subtitle1" color="inherit" noWrap className={classes.title}>
        <Box>
          {ps = ProjectSelector()}
          {ps && isLocationPath("annotation") && DataSetSelector()}
        </Box>
      </Typography>
    );

    function onProjectSelect(event, project) {
      const currentProjectId = projectId;
      localStorage.removeItem("selectedDataSetId");
      localStorage.setItem('currentProject', currentProjectId);
      dispatch(dispatchChainingComboProjectDataset({
        projectId: project._id,
        name: project.name,
        composerConfig: project.composerConfig,
      }))
      .then((res) => {
        const projectId = project._id;

        if (projectId == currentProjectId)
          return;
        if (currentPathname != "annotation")
          history.push(`/${currentPathname}/${projectId}`);
        switch (currentPathname) {
        /* moved to Intents.componentDidUpdate TODO: other cases likewise
          case "intents":
            dispatch(fetchIntents({ projectId }));
            break;
         */
          case "slots":
            dispatch(fetchAllSlots({ projectId }));
            break;
          case "configs":
            // FIXME: dispatch(fetchConfigs({ projectId }));
            break;
          case "annotation":
            dispatch(fetchTestModelList({ projectId }));
            
            if (localStorage.getItem("selectedDataSetId")) {
              history.push(`/annotation/${projectId}/${localStorage.getItem("selectedDataSetId")}`);
            }
            break;
          case "filters":
            dispatch(fetchIntents({ projectId }));
            break;
          case "train":
            dispatch(fetchModelList({ projectId }));
            dispatch(fetchModelConfigList({ projectId }));
            break;
        /* moved to Tests.componentDidUpdate TODO: other cases likewise
          case "tests":
            dispatch(fetchTestModelList({ projectId }));
            break;
         */
          case "auto_annotation":
            dispatch(fetchAutoModelList({ projectId }));
            break;
          default:
            break;
        }
      });
    };

    function ProjectSelector() {
      // console.log(currentPathname);
      return isLocationPath("sign-in") || !projects ? null
        : [ t("common.project"), ': ',
            ...[ isLocationPath("projects") ? projectName : elem() ]];

      function elem() {
        return (
          <Autocomplete
            key="ProjectSelect"
            size="small"
            style={{ marginLeft: "5px" }}
            classes={{
              paper: classes.paper,
              option: classes.option,
              inputRoot: classes.inputAuto,
              root: classes.rootAuto,
              popupIndicator: classes.labelAuto,
              clearIndicator: classes.labelAuto,
            }}
            disableClearable
            options={projects}
            getOptionLabel={(option) => option.name}
            value={projects.find(p => p._id === projectId) || null}
            onChange={onProjectSelect}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField style={{ marginTop: "1px" }}
                {...params}
                size="small"
              />
            )}
          />
        )
      }
    }
  }

  const _IsAdmin = getRunConfigOption('enableRoleModel') && IsAdmin();
  const key_users = _IsAdmin && !oauthServer? ["users"] : [];
  const key_user_groups = _IsAdmin? ["user_groups"] : [];

  /** When fixScroll style is applied, on Annotation tab, when we change to AnnotationSimilarity,
   * <main> suddenly and for unknown reason scrolls down the size of <AppBar> and it top goes up
   * beyond visibility, so this fix is intended to undo this strange behavior.
   */
  const fixUncontrolledScroll = e => {
    const n = e.target;
    if (n == e.currentTarget && n.scrollTop) {
      console.log('fixUncontrolledScroll', n.scrollTop, n);
      n.scrollTo(0,0);
    }
  };

  const ref = useRef();

  return (
    <div className={classes.root}>
      <SnackbarMessage/>
      {showSettings && <SettingsDialog onClose={() => setShowSettings(false)}/>}
      <Router history={history}>
        <CssBaseline/>
        <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
          <Toolbar className={classes.toolbar}>
            {isLoggedIn &&
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
              >
                <MenuIcon/>
              </IconButton>}
            {ProjectAndDatasetSelector()}
            {/* Breadcrumbs */}
            <div style={{ marginRight: 10 }}><LanguageSelect/></div>
            {isLoggedIn && <Box m={2}>{_IsAdmin ? t("common.admin") : t("common.user_name")}: {GetUserName()}</Box>}
            {isLoggedIn && getRunConfigOption('globalSettings') && (
              <IconButton onClick={() => setShowSettings(true)} title={t("common.settings")} color="inherit">
                <SettingsIcon color="inherit"/>
              </IconButton>
            )}
            <InfoPopover/>
            {isLoggedIn &&
              <IconButton onClick={handleLogOut} title={t("common.sign_out")} color="inherit">
                <ExitToAppIcon color="inherit"/>
              </IconButton>}
          </Toolbar>
          <ProgressIndicator/>
        </AppBar>
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
          open={open}
        >
          <div className={classes.toolbarIcon}>
            <IconButton aria-label={t("common.logout")} onClick={handleDrawerClose}>
              <ChevronLeftIcon/>
            </IconButton>
          </div>
          <Divider/>
          <List>
            {
              [...key_users, ...key_user_groups, "projects", "datasets", "intents", "slots", "entities", "annotation", "filters", "configs", "train", "tests", "auto_annotation", "batches"].map(
              (item, idx) => {
                const _ds = item == "annotation" ? `/${selectedDataSetId || ""}` : "";
                const is_admin_item = ["users", "user_groups"].includes(item);
                
                return (
                  <ListItem selected={isListItemSelected(item)} onClick={() => setCurrentPathname(item)} button
                    component={Link} to={`/${item}/${projectId}${_ds}`} key={"list-" + idx}>
                    <ListItemText className={clsx(is_admin_item && classes.menuItemAdmin)}>{t("menu." + item)}</ListItemText>
                  </ListItem>
                );
              })
            }
          </List>
        </Drawer>
        <main className={classes.main} onScroll={fixUncontrolledScroll}>
          <div className={classes.appBarSpacer}/>
          <Container ref={ref} maxWidth={false} className={classes.container}>
            <Grid container spacing={3}>
              {/* main container with preview info*/}
              <Switch>
                <Route exact path="/">
                  <Redirect to="/projects"/>
                </Route>
                <Route exact path="/sign-in">
                  <SignIn {...{ oauthServer }}/>
                </Route>
                <Route exact path="/nlu_suite_callback">
                  <OAuthCallback/>
                </Route>
                {Routes.map(({ path, Section, exact, is_admin }) => (
                  (!is_admin || _IsAdmin) && (
                    <PrivateRoute key={String(path)} path={path} exact={exact}>
                      {projects && <Section containerRef={ref} history={history}/>}
                    </PrivateRoute>
                  )
                ))}
              </Switch>
            </Grid>
            <SimpleBackdrop/>
          </Container>
        </main>
      </Router>
    </div>
  );
}

function PrivateRoute({ children, ...rest }) {
  const token = GetToken();

  return (
    <Route
      {...rest}
      render={({ location }) =>
        token ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/sign-in",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
