import React, { Fragment, useState, useEffect, createRef } from "react";
import clsx from "clsx";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { setIsLoading } from "../../../features/settings";
import {
  setEntities,
  fetchAllEntities,
} from "../../../features/lookup";
import { setMessageState } from "../../../features/messageInfo";
import {
  getDataNew,
  postDataNew,
  deleteDataNew,
  uploadFileNew,
  putDataNew,
} from "../../../core/fetchService";
import {
  MESSAGE_STATUS,
  DIALOG_USER_STATE,
  ENTITYLIST,
  LookupScope,
} from "../../../core/constants";
import { GlobalState, download, showWarning, showError, CHK_FAIL } from "../../../core/utils";
import iconList from '../../components/helpers/iconList';
import IconButton from '../../components/material-ui/IconButton';

import DeleteIcon from "@material-ui/icons/Delete";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MenuItem from "@material-ui/core/MenuItem";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import ConfirmDialog from "../../components//confirmDialog";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import ListIcon from "@material-ui/icons/List";
import Tooltip from "@material-ui/core/Tooltip";
import AddBoxIcon from "@material-ui/icons/AddBox";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import PublishIcon from "@material-ui/icons/Publish";
import GetAppIcon from "@material-ui/icons/GetApp";
import UpdateIcon from "@material-ui/icons/Update";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation, useTranslation } from "react-i18next";
import Upload from "../../components/fieldUpload";
import DownloadDialog from "./DownloadDialog";
import CloseBar from "../../components/dialogCloseBar";
import notBackdropClicked from "../../components/helpers/notBackdropClicked";

const LIST_VALUE_HEIGHT = 400;

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
  listSubhead: {
    textAlign: "left",
    paddingLeft: "30px",
  },
  listMain: {
    width: "100%",
  },
  list: {
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "auto",
  },
  upload: {
    display: "inline-flex",
  },
  entityLabel: {
    backgroundColor: "lightgrey",
  },
  dialogField: {
    margin: 8
  }
});

function EditDialog(props) {
  const { onClose, action, entityName, valueName, classes } = props;
  const [name, setName] = useState(null);
  const [scope, setScope] = useState(null);
  const [pattern, setPattern] = useState(null);
  const [entityType, setEntityType] = useState(null);
  const [formData, setFormData] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    setName(entityName || valueName || "");
    setScope(props.scope || "custom");
    setEntityType(props.entityType || "list");
    setPattern(props.pattern || "");
  }, [entityName, valueName, props.scope, props.entityType, props.pattern]); //refresh effect if one of this params changed

  const handleClose = save => {
    onClose(save, { name, scope, entityType, formData, pattern }, null);
  };

  const title = () => {
    if (action) {
      return t(`entities.${action}`);
    }
    
    return "";
  };

  return (
    <Dialog
      fullWidth={true}
      open={true}
      onClose={notBackdropClicked(() => handleClose(false))}
    >
      <CloseBar onClose={() => handleClose(false)} title={title()}/>
      <DialogContent dividers style={{ overflow: "hidden" }}>
        {action && action !== ENTITYLIST.UPLOAD_ENTITY && (
          <TextField
            required
            size="small"
            className={classes.dialogField}
            label={t("common.name")}
            onChange={(event) => {
              let value = event.target.value;
              if (
                action === ENTITYLIST.UPDATE_ENTITY ||
                action === ENTITYLIST.ADD_ENTITY
              ) {
                value = value.replace(/[^0-9A-Za-z-_.]/gi, "");
              }

              setName(value);
            }}
            variant="outlined"
            fullWidth
            value={name || ""}
            //defaultValue={action === ENTITYLIST.UPDATE_ENTITY || action === ENTITYLIST.UPDATE_VALUE ? name : null}
          />
        )}
        {action &&
          (action === ENTITYLIST.ADD_ENTITY ||
            action === ENTITYLIST.UPLOAD_ENTITY ||
            action === ENTITYLIST.UPDATE_ENTITY) && (
            <Fragment>
              {action !== ENTITYLIST.UPLOAD_ENTITY && (
                <TextField
                  select
                  className={classes.dialogField}
                  size="small"
                  label={t("entities.entity_type")}
                  fullWidth
                  value={entityType}
                  onChange={(event) => setEntityType(event.target.value)}
                  variant="outlined"
                >
                  {["list", "pattern", "any"].map((option, idx) => (
                    <MenuItem
                      value={option}
                      key={idx}
                      selected={option === entityType}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              <TextField
                select
                className={classes.dialogField}
                size="small"
                label={t("entities.entity_scope")}
                fullWidth
                value={scope}
                onChange={(event) => setScope(event.target.value)}
                variant="outlined"
              >
                {["custom", "public"].map((option, idx) => (
                  <MenuItem
                    value={option}
                    key={idx}
                    selected={option === scope}
                  >
                    {option}
                  </MenuItem>
                ))}
              </TextField>
              {entityType === "pattern" && (
                <TextField
                  required
                  size="small"
                  className={classes.dialogField}
                  label={t("entities.pattern")}
                  onChange={(event) => setPattern(event.target.value)}
                  variant="outlined"
                  fullWidth
                  defaultValue={pattern || ""}
                />
              )}
            </Fragment>
          )}
        {action && action === ENTITYLIST.UPLOAD_ENTITY && (
          <div style={{ marginLeft: "8px" }}>
            <Upload
              onFileLoad={(fileType, formData) => setFormData(formData)}
              fileTypeRegex={/([a-zA-Z0-9\s_\\.\-():])+(.json)$/i}
            />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false)} color="primary">
          {t("common.cancel")}
        </Button>
        {/* eslint-disable-next-line no-undef */}
        <Button
          disabled={action && action === ENTITYLIST.UPLOAD_ENTITY? !formData: !name || !scope || !entityType || (entityType === "pattern" && !pattern)}
          onClick={() => handleClose(true)}
          color="primary"
          autoFocus
        >
          {t("common.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

class Entities extends React.Component {
  constructor(props) {
    super(props);
    this.listItemRef = createRef();
    this.listValueRef = createRef();
    this.state = {
      scope: null,
      entityType: null,
      entityId: null,
      entityName: null,
      valueId: null,
      valueName: null,
      pattern: null,
      synonymId: null,
      textSearchEntity: "",
      textSearchValue: "",
      textSearchSynonym: "",
      action: null,
      rowId: null,
      showModal: false,
      modalContent: null,
      editDialogOpen: false,
      assotiatedEntitiesList: null,
      synIndex: -1,
      isDownloadDialogOpen: false,
      deleteDialog: false
    };
    this.globalState = new GlobalState(this.getAllEntity);
  }

  getAllEntity = () => { this.props.dispatch(fetchAllEntities(this.props)) };

  abortController = new AbortController();

  componentDidMount()           { this.globalState.componentDidMount() }
  componentDidUpdate(prevProps) { this.globalState.componentDidUpdate(prevProps, this.props) }
  componentWillUnmount()        { this.abortController.abort() }

  handleCloseModal = modalState => {
    const { rowId, action, entityId } = this.state;

    this.setState({ showModal: false });

    if (modalState == DIALOG_USER_STATE.AGREE) {
      if (action == ENTITYLIST.ADD_SYNONYM)
        return this.handleCloseEditDialog(true, { name: rowId }, rowId);

      const url
        = action == ENTITYLIST.ADD_VALUE ?  `/api/lookup/${entityId}/value/${rowId}`
        : action == ENTITYLIST.ADD_ENTITY ? `/api/lookup/${rowId}` : undefined;

      if (!url)
        return CHK_FAIL(`Unknown action value : '${action}'`);

      deleteDataNew(url, this.props.dispatch, data => {
        this.getAllEntity(); // FIXME update w/o reload
      }, {
        error_prefix: `${action.toUpperCase()} error`,
      });
    }
  };
  
  handleCloseEditDialog = (save, dialogState, rowId) => { // dialogState = {name, scope, formData}
    const { action, entityId, valueId, synIndex, deleteDialog } = this.state;
    const { projectId, entities, t, dispatch } = this.props;

    const getValue = (entities, e_id, v_id) =>
      entities.find(e => e._id == e_id).values.find(v => v._id == v_id);
    const isActiveList = k => ENTITYLIST[k] == action;
    
    if (!save)
      return this.setState({ editDialogOpen: false });

    if (!dialogState.name && action != ENTITYLIST.UPLOAD_ENTITY)
      return showWarning(dispatch, t)("entities.name_empty");

    if (dialogState.name && ['ADD_SYNONYM', 'UPDATE_SYNONYM'].some(isActiveList) && !deleteDialog) {
      const areEq = (a, b) => String(a).trim() == String(b).trim();

      for (const e of entities || [])
        for (const v of e.values || [])
          if (v._id == valueId && v.synonyms.find(s => areEq(s, dialogState.name)))
              return showWarning(dispatch, t)('entities.already_exists');
    }

    if (action == ENTITYLIST.UPLOAD_ENTITY) {
      if (!dialogState.formData)
        return showWarning(dispatch, t)("entities.file_empty");

      dialogState.formData.append("scope", dialogState.scope);
      //dialogState.formData.append('entityType', dialogState.entityType);
      this.handleClickUploadFile(null, dialogState.formData);
      return;
    };

    if (action == ENTITYLIST.UPDATE_SYNONYM
        && getValue(entities, entityId, valueId)?.synonyms?.length <= synIndex) {
      CHK_FAIL(`wtf?`);
      return showError(dispatch, t)(`Internal error // stack trace in console`);
    }

    const params = {
      get url() {
        switch (action) {
          case ENTITYLIST.ADD_ENTITY:     return `/api/lookup`;
          case ENTITYLIST.ADD_VALUE:      return entityId ? `/api/lookup/${entityId}/value` : null;
          case ENTITYLIST.ADD_SYNONYM:    return entityId && valueId ? `/api/lookup/${entityId}/value/${valueId}` : null;
          case ENTITYLIST.UPDATE_ENTITY:  return `/api/lookup/${entityId}`;
          case ENTITYLIST.UPDATE_VALUE:
          case ENTITYLIST.UPDATE_SYNONYM: return `/api/lookup/${entityId}/value/${valueId}`;
          default:                        return (CHK_FAIL(`Unknown action value : '${action}'`), null);
        }
      },

      get data() {
        switch (action) {
          case ENTITYLIST.ADD_ENTITY:
            return { lookup: { ...dialogState, project: projectId, values: [] } };

          case ENTITYLIST.ADD_VALUE:
          case ENTITYLIST.UPDATE_VALUE:
            return { value: { value: dialogState.name }, project: projectId };

          case ENTITYLIST.ADD_SYNONYM:
            let synonyms = entities.find(e => e._id == entityId)
              ?.values.find(v => v._id == valueId)?.synonyms;
            synonyms = rowId
              ? synonyms.filter(s => s != rowId && s !== "")
              : [...synonyms, dialogState.name];
            return { value: { synonyms: synonyms }, project: projectId };

          case ENTITYLIST.UPDATE_ENTITY:
            return {
              lookup: {
                name: dialogState.name,
                scope: dialogState.scope,
                entityType: dialogState.entityType,
                pattern: dialogState.pattern,
                project: projectId,
              },
            };

          case ENTITYLIST.UPDATE_SYNONYM:
            const val = entities.find(e => e._id == entityId).values.find(v => v._id == valueId);
            const newSyn = val.synonyms.map((s, i) => synIndex == i ? dialogState.name : s);
            return { value: { ...val, synonyms: newSyn } };

          default:
            CHK_FAIL(`Unknown action value : '${action}'`);
            return undefined; // avoid warning
        }
      },
    };
    if (!params.url)
      showError(dispatch, t)('Internal error // check console output');

    const sendData = rowId || ['ADD_SYNONYM', 'UPDATE_ENTITY', 'UPDATE_VALUE', 'UPDATE_SYNONYM'].some(isActiveList)
      ? putDataNew : postDataNew;

    sendData(params.url, params.data, dispatch, data => {
      switch (action) {
        case ENTITYLIST.ADD_SYNONYM:
          this.setState({ textSearchSynonym: '' });
          /* fallthrough */
        case ENTITYLIST.UPDATE_VALUE:
        case ENTITYLIST.UPDATE_SYNONYM:
          return dispatch(setEntities(entities.map(e =>
            e._id == entityId ? {
              ...e, values: e.values.map(v => v._id != data.value._id ? v : data.value),
            } : e
          )));

        case ENTITYLIST.ADD_VALUE:
          this.setState({ textSearchValue: '', textSearchSynonym: '' });

          dispatch(setEntities(entities.map(e => (
            e._id == entityId ? { ...e, values: [data.value, ...e.values] } : e
          ))));

          return setTimeout(() => {
            const node = this.listValueRef.current;
            if (!node)
              return;
            const li = node.parentNode?.children[1]?.children[0]; // FIXME: wtf?
            const ul = node.parentNode;
            li && li.click();
            ul.scrollTo({ top: 0, behavior: "smooth" });
          }, 1000);

        case ENTITYLIST.ADD_ENTITY:
          dispatch(setEntities([...entities, data.lookup]));

          return this.setState({
            entityId: data.lookup._id,
            textSearchEntity: '',
            textSearchValue: '',
            textSearchSynonym: '',
          }, () => {
            setTimeout(() => {
              const node = this.listItemRef.current;
              node && node.scrollIntoView({ behavior: "smooth", block: "start" });
            }, 1000);
          });

        default: this.getAllEntity();
      }
    }, {
      error_prefix: `${action.toUpperCase()} error`,
    }).then(() => {
        this.setState({ editDialogOpen: false });
    });

    this.setState({ deleteDialog: false });
  };

  handleDeleteEntity = (_id, name) => {
    const { t } = this.props;
    this.setState({
      modalContent: `${t("entities.delete_entity")}: ${name}?`,
      title: t("entities.confirm_title"),
      showModal: true,
      rowId: _id,
      action: ENTITYLIST.ADD_ENTITY,
      deleteDialog: true
    });
  };

  handleDeleteValue = (_id, name) => {
    const { t } = this.props;
    this.setState({
      modalContent: `${t("entities.delete_val")}: ${name}?`,
      title: t("entities.delete_val"),
      showModal: true,
      rowId: _id,
      action: ENTITYLIST.ADD_VALUE,
      deleteDialog: true
    });
  };

  handleDeleteSynonym = (_id, name) => {
    const { t } = this.props;
    this.setState({
      modalContent: `${t("entities.delete_syn")}: ${name}?`,
      title: t("entities.delete_syn"),
      showModal: true,
      rowId: _id,
      action: ENTITYLIST.ADD_SYNONYM,
      deleteDialog: true
    });
  };

  handleEntityClick = entityId =>
    entityId != this.state.entityId
      && this.setState({ entityId, textSearchValue: '', textSearchSynonym: '' });

  handleValueClick = valueId =>
    valueId != this.state.valueId
    && this.setState({ valueId, textSearchSynonym: '' });

  handleSynonymClick = synonymId =>
    this.setState({ synonymId });

  handleClickUploadFile = (fileType, formData) => {
    formData.append("project", this.props.projectId);

    uploadFileNew(`/api/lookup/upload`, formData, this.props.dispatch, data => {
      this.getAllEntity();
    }, {
      error_prefix: "Upload Entity error",
    });

    this.setState({ editDialogOpen: false, fileType: null });
  };

  handleDownloadFile = (scope) => {
    download('/api/lookup/download/'
             +(scope == LookupScope.Custom ? `project?project=${this.props.projectId}` : 'public'),
             this.props.dispatch);
    this.setState({ isDownloadDialogOpen: false });
  };

  handleClickNewItem = (action) => {
    const openEditDialog = { action, editDialogOpen: true };
    if (action === ENTITYLIST.ADD_ENTITY) {
      this.setState({
        ...openEditDialog,
        entityName: null,
        scope: null,
        entityType: null,
        pattern: null,
        valueName: null,
      });
    } else if (action === ENTITYLIST.ADD_VALUE || action === ENTITYLIST.ADD_SYNONYM) {
      this.setState({
        ...openEditDialog,
        entityName: null,
        valueName: null,
      });
    } else {
      this.setState(openEditDialog);
    }
  };

  render() {
    const { classes, t, entities } = this.props;
    const {
      action,
      textSearchEntity,
      textSearchValue,
      textSearchSynonym,
      valueId,
      entityId,
      modalContent,
      showModal,
      editDialogOpen,
      entityName,
      scope,
      valueName,
      entityType,
      pattern,
      title,
      isDownloadDialogOpen,
    } = this.state;

    const entityGroup = [...(entities || [])]
      .sort((a,b) => a.scope.localeCompare(b.scope)) // [c]ustom, [p]ublic, [s]ystem :)
      .reduce((a,e) => ({ ...a, [e.scope]: [...(a[e.scope] || []), e]}), {});

    return (
      <Fragment>
        {showModal && (
          <ConfirmDialog
            open={showModal}
            title={title}
            content={modalContent}
            closeModal={(modalState) => this.handleCloseModal(modalState)}
          />
        )}
        {editDialogOpen && <EditDialog
          {...this.props}
          scope={scope}
          entityType={entityType}
          action={action}
          entityName={entityName}
          valueName={valueName}
          pattern={pattern}
          classes={classes}
          onClose={this.handleCloseEditDialog}
        />}
        <DownloadDialog
          isOpen={isDownloadDialogOpen}
          onAccept={this.handleDownloadFile}
          onReject={() => this.setState({ isDownloadDialogOpen: false })}
        />

        <Grid container className={classes.root} spacing={3} wrap="nowrap" style={{ overflow: "auto" }}>
          <Grid item xs>
            <List className={classes.listMain}>
              <ListItem role={undefined} dense>
                <ListItemText primary={t("entities.entity")} />
                <ListItemSecondaryAction>
                  {iconList([
                    ['common.upload',       PublishIcon,  () => this.handleClickNewItem(ENTITYLIST.UPLOAD_ENTITY)],
                    ['common.download',     GetAppIcon,   () => this.setState({ isDownloadDialogOpen: true })],
                    ['entities.new_entity', AddBoxIcon,   () => this.handleClickNewItem(ENTITYLIST.ADD_ENTITY)],
                    ['common.refresh',      UpdateIcon,   this.getAllEntity],
                  ], {
                    t,
                    size: 'medium',
                    fontSize: 'small',
                  })}
                </ListItemSecondaryAction>
              </ListItem>
            </List>
            <ListWithSearch
              cond={entities}
              searchValue={textSearchEntity}
              setSearchValue={v => this.setState({ textSearchEntity: v })}
              classes={classes}
            >
              {entities &&
                  Object.keys(entityGroup).map(scope => (
                    <Fragment key={scope}>
                      <ListItem className={classes.entityLabel}>
                        {scope}
                      </ListItem>
                      {entityGroup[scope].map((entity, idx) => {
                        const { _id, name, entityType, pattern } = entity;
                        const labelId = `checkbox-list-label-${_id}`;

                        return textSearchEntity && !name.toLocaleLowerCase().includes(textSearchEntity.toLocaleLowerCase()) ? null : (

                          <ListItem
                            selected={_id === entityId}
                            ref={this.listItemRef}
                            key={idx}
                            role={undefined}
                            dense
                            button
                            onClick={() => this.handleEntityClick(_id)}
                          >
                            <ListItemText id={labelId} primary={name} />
                            <ListItemSecondaryAction>
                              <Button disabled size="small" style={{marginTop: 2}}>
                                {entityType}
                              </Button>
                              {iconList([
                                ['entities.update_entity',   ListIcon,   () => {
                                  this.setState({
                                                action: ENTITYLIST.UPDATE_ENTITY,
                                                editDialogOpen: true,
                                                entityId: _id,
                                                entityName: name,
                                                scope,
                                                entityType,
                                                pattern,
                                  });
                                }],
                                  ['common.delete', DeleteIcon, () => this.handleDeleteEntity(_id, name)],
                              ], {
                                t, fontSize: 'small', hidden: scope == 'system',
                              })}
                            </ListItemSecondaryAction>
                          </ListItem>
                        );
                      })}
                    </Fragment>
                  ))}
            </ListWithSearch>
          </Grid>

          {(() => {
            const entityType = entities?.find(f => f._id == entityId)?.entityType;
            const v = entities?.find(e => e._id == entityId)?.values || [];
            const s = v.find(f => f._id == valueId)?.synonyms || [];

            const values = [...v];
            const synonyms = [...s];

            const showList = (items, search_value, listItemProps, listIconName, onEditClick, onDeleteClick) =>
              items.map((v,i) => [v,i])
                .sort((a, b) => a[0].value.localeCompare(b[0].value))
                .map(([v,i]) => (
                  search_value && !v.value.toLocaleLowerCase().includes(search_value.toLocaleLowerCase())
                    ? null : (
                      <ListItem key={i} role={undefined} dense button {...listItemProps(v)}>
                        <ListItemText id={`checkbox-list-label-${i}`} primary={v.value}/>
                        <ListItemSecondaryAction>
                          {iconList([
                            [listIconName,    ListIcon,   onEditClick(v, i)],
                            ['common.delete', DeleteIcon, onDeleteClick(v)],
                          ], { t, fontSize: 'small' })}
                        </ListItemSecondaryAction>
                      </ListItem>
                    )));

            if (entityType === "pattern")
              return (
                <Grid item xs={8}>
                  <List>
                    <ListItem role={undefined} dense>
                      <ListItemText primary={t("entities.pattern")} />
                    </ListItem>
                  </List>
                  <List>
                    <TextField
                      disabled
                      value={
                        entities.find((f) => f._id === entityId)?.pattern || ""
                      }
                      fullWidth
                      margin="dense"
                      variant="outlined"
                    />
                  </List>
                </Grid>
              );

            if (entityType === "any")
              return <Grid item xs={8}/>;

            return (
              <Fragment>
                <Grid item xs>
                  <List>
                    <ListItem role={undefined} dense>
                      <ListItemText primary={t("entities.val")} />
                      <ListItemSecondaryAction>
                        {entityId && iconList([['entities.add_value', AddBoxIcon, () => (
                          this.handleClickNewItem(ENTITYLIST.ADD_VALUE)
                        )]], { t, size: 'medium', fontSize: 'small' })}
                      </ListItemSecondaryAction>
                    </ListItem>
                  </List>
                  <ListWithSearch
                    cond={entities && entityId}
                    searchValue={textSearchValue}
                    setSearchValue={v => this.setState({ textSearchValue: v })}
                    classes={classes}
                  >
                    {showList(
                      values,
                      textSearchValue,
                      v => ({
                        selected: v._id == valueId,
                        ref:      this.listValueRef,
                        onClick:  () => this.handleValueClick(v._id),
                      }),
                      'entities.update_value',
                      (v, i) => () => {
                        this.setState({
                          action: ENTITYLIST.UPDATE_VALUE,
                          editDialogOpen: true,
                          valueId: v._id,
                          valueName: v.value,
                          entityName: null,
                        });
                      },
                      v => () => {
                        this.handleDeleteValue(v._id, v.value)
                      })}
                  </ListWithSearch>
                </Grid>

                <Grid item xs>
                  <List>
                    <ListItem role={undefined} dense>
                      <ListItemText primary={t("entities.syn")} />
                      <ListItemSecondaryAction>
                        {valueId && iconList([['entities.add_synonym', AddBoxIcon, () => (
                          this.handleClickNewItem(ENTITYLIST.ADD_SYNONYM)
                        )]], { t, size: 'medium', fontSize: 'small' })}
                      </ListItemSecondaryAction>
                    </ListItem>
                  </List>

                  <ListWithSearch
                    cond={entities && entityId && valueId}
                    searchValue={textSearchSynonym}
                    setSearchValue={v => this.setState({ textSearchSynonym: v })}
                    classes={classes}
                  >
                    {showList(
                      synonyms.map(s => ({value: s})),
                      textSearchSynonym,
                      v => ({
                        onClick: () => this.handleSynonymClick(v.value),
                      }),
                      'entities.update_synonym',
                      (v, i) => () => {
                        this.setState({
                          action: ENTITYLIST.UPDATE_SYNONYM,
                          editDialogOpen: true,
                          valueName: v.value,
                          synIndex: i,
                        });
                      },
                      v => () => {
                        this.handleDeleteSynonym(v.value, v.value);
                      })}
                  </ListWithSearch>
                </Grid>
              </Fragment>
            );
          })()}
        </Grid>
      </Fragment>
    );
  }
}

function ListWithSearch({ cond, searchValue, setSearchValue, classes, children }) {
  const { t } = useTranslation();
  return (
    <List
      className={clsx(classes.listMain, classes.list)}
      style={{ height: LIST_VALUE_HEIGHT }}
      subheader={
        cond && (
          <ListSubheader
            component="div"
            style={{display: "flex"}}
            className={classes.listSubhead}
          >
            <IconButton disabled size='medium' Icon={SearchIcon}/>
            <InputBase
              placeholder={t("common.search")}
              onChange={event => setSearchValue(event.target.value)}
              onKeyDown={event => event.key == 'Escape' && searchValue && setSearchValue('')}
              value={searchValue}
            />
          </ListSubheader>
        )
      }
    >
      {children}
    </List>
  );
}

const mapStateToProps = (state) => ({
  projectId: state.settings.projectInfo.projectId,
  entities: state.lookup.entities,
});

export default withRouter(
  connect(mapStateToProps)(withStyles(styles)(withTranslation()(Entities)))
);
