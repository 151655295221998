import React, { Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import FileCopyIcon from "@material-ui/icons/FileCopy";
import DeleteIcon from "@material-ui/icons/Delete";
import TableCell from "@material-ui/core/TableCell";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";
import GetAppIcon from "@material-ui/icons/GetApp";
import BlockIcon from "@material-ui/icons/Block";
import BrightnessHighIcon from "@material-ui/icons/BrightnessHigh";
import PublishIcon from "@material-ui/icons/Publish";
import LinearProgress from '@mui/material/LinearProgress';

import IconButton from "../../components/material-ui/IconButton";

import {
  groupById, setDefaultRowsOnPage, getRunConfigOption,
  CHK_FAIL, withTranslation,
}                                                               from "../../../core/utils";
import { getDataNew as getData, postDataNew, deleteDataNew }    from "../../../core/fetchService";
import { MESSAGE_STATUS, DIALOG_USER_STATE, BTN }               from "../../../core/constants";
import { setIsLoading, fetchProjects, fetchModelConfigList }    from "../../../features/settings";
import {
  fetchModelList, setModelList, updateDataModel,
}                                                               from "../../../features/model";
import { setMessageState }                                      from "../../../features/messageInfo";

import EnhancedTable                                            from "../../components/projectTable";
import ConfirmDialog                                            from "../../components/confirmDialog";
import {
  viewStatus,
  linkStyle as trainStatusLinkStyle,
  Runnable,
}                                                               from "../../components/taskStatus";

import Scrollable                                               from '../../components/helpers/scrollable';

import EditDialog                                               from "./editModelDialog";
import { ModelStatusDialog }                                    from "./modelStatusDialog";

function viewTrainStatus(row, cell) {
  const s = row;
  const s_epochs = s?.progress?._outer;
  const percent = s_epochs?.percent + s?.progress?.percent / (s_epochs?.total || 1);
  return viewStatus(row, cell, {
    s_obj: row,
    percent,
    in_progress: ['NLU_NB', 'ASR_E2E'].includes(row.type) ? 'Train' : '',
  });
}

class Train extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      showModal: null,
      dialogTitle: null,
      modalContent: null,
      btnNameAgree: null,
      downloadModal: undefined,
      showModelStatusDialog: false,
      editDialogOpen: false,
      modelCloneName: "",
      editModelId: null,
      nluConfigText: null,
    };

    this.eventSource = new EventSource(`/events`);
    this.scrollable = new Scrollable(this);
    this.runnable = new Runnable({
      statusName: 'Train',
      getStatus: m => m?.status,
      updateStatus: this.getModelList,
      addActiveStatuses: ['Export'],
    });
  }

  isTraining = m => this.runnable.isObjActive(m);

  setMessageErrorState = (obj) => {
    this.props.dispatch(setMessageState(obj));
    this.props.dispatch(setIsLoading(false));
  };

  showSnackBar = (obj) => {
    this.props.dispatch(setMessageState(obj));
  };

  getModelList = () => {
    (this.props.modelList || []).forEach(m => {
      if (m.status == 'Export...')
        this.fetchDownloadStatus(m._id, m.name, null, true);
    });
    this.props.dispatch(fetchModelList(this.props));
    this.props.dispatch(fetchModelConfigList(this.props));
  };

  componentDidMount() {
    const { projectId, modelList, t, projects, dispatch } = this.props;

    if (projectId && !projects)
      dispatch(fetchProjects());

    this.getModelList();

    this.eventSource.addEventListener("message", event => {
      this.getModelList();
    });

    // eventSource.onerror = function (e) {
    //  if (this.readyState == EventSource.CONNECTING) {
    //    console.log(`Переподключение (readyState=${this.readyState})...`);
    //  } else {
    //    console.log("Произошла ошибка.");
    //  }
    // };

    this.scrollable.componentDidMount();
  }

  componentDidUpdate() {
    this.runnable.componentDidUpdate(this.props.modelList);
  }

  componentWillUnmount() {
    this.runnable.componentWillUnmount();

    if (this.eventSource) {
      this.eventSource.close();
    }
    this.abortController.abort();
  }

  abortController = new window.AbortController();

  handleEditRow = id => () => {
    getData(`/api/model/${id}`, this.props.dispatch, data => {
      if (data.model) {
        this.setState({ editDialogOpen: true, editModelId: id });
      }
    });
  };

  handleCloneRow = (id, name) => () => {
    const { t } = this.props;
    this.setState({
      editModelId: id,
      modelCloneName: name,
      showModal: 'clone',
      dialogTitle: t("train.clone"),
      modalContent: (
        <TextField
          onChange={(event, value) => {
            this.setState({ modelCloneName: event.target.value });
          }}
          defaultValue={name}
          size="small" style={{ margin: 8, paddingRight: 17 }}
          label={t("train.new_model_name")}
          variant="outlined"
          fullWidth
        />
      ),
      btnNameAgree: t('common.clone'),
    });
  };

  fetchDownloadStatus = (id, name, action, silent) => {
    const { dispatch, t } = this.props;
    const close = () => this.setState({ downloadModal: null });
    const show_link = url => { this.setState({ downloadModal: <a href={url} onClick={close}>{name}</a> }) };
    getData(`/api/model/${id}/download/remote_status`, dispatch, data => {
      if (data.status == 'ready')
        return show_link(data.url);

      const show_popup = () => {
        this.setState({ downloadModal: <div>{t('train.export_model_files', { name })}<br/><br/><LinearProgress/></div> });
      };
      if (action)
        action(show_popup);
      else if (!silent)
        show_popup()
    });
  };

  handleDownloadModel = (id, name) => () => {
    this.fetchDownloadStatus(id, name, () => {
      getData(`/api/model/${id}/download/prepare`,
              this.props.dispatch,
              () => { this.fetchDownloadStatus(id, name); this.getModelList() });
    })
  };

  handleDeleteRow = (id, name) => () => {
    const { t } = this.props;
    this.setState({
      editModelId: id,
      showModal: 'delete',
      dialogTitle: t("train.delete"),
      modalContent: `${t("train.delete_trained_model")}: "${name}"?`,
      btnNameAgree: null,
    });
  };

  fetchGetTrainAction = async (id, action) => {
    if (action == 'stop') // this is to disable STOP button in case a 'stop' request is delayed/hanged up
      this.props.dispatch(updateDataModel({ ...this.props.modelList.find(m => m._id == id), status: 'Waiting...'}));

    const { dispatch } = this.props;

    getData(`/api/model/${id}/${action}`, dispatch, data => {

      data.model && dispatch(updateDataModel(data.model));
      // make blue and add time 20 sec
      data.message && dispatch(setMessageState({
        snackBarMessages: data.message,
        snackBarVariant: MESSAGE_STATUS.INFO,
        snackBarState: true,
        snackBarDuration: 20000,
      }));
    });
  };

  handleCloseModal = (modalState) => {
    const { showModal: action, modelCloneName, editModelId } = this.state;
    const { dispatch, modelList } = this.props;
    this.setState({ showModal: null });
    if (modalState != DIALOG_USER_STATE.AGREE)
      return;
    if (action == 'clone') {
      const from = modelList.find(m => m._id === editModelId);
      const model = { ...from, name: modelCloneName };
      delete model._id;

      postDataNew(`/api/model`, { model, from }, dispatch, this.getModelList);
      this.setState({ modelCloneName: null });

    } else if (action == 'delete') {
      deleteDataNew(`/api/model/${editModelId}`, dispatch, this.getModelList);
    } else {
      CHK_FAIL(`unknown modal action: ${action}`);
    }
  };

  handleClickNewRow = () => {
    this.setState({ editModelId: null, editDialogOpen: true });
  };

  handleCloseEditDialog = (action) => {
    this.setState({ editDialogOpen: false });
    if (action === BTN.SAVE) {
      this.getModelList();
    }
  };

  render() {
    const {
      editModelId, downloadModal, editDialogOpen,
      showModal, dialogTitle, modalContent, btnNameAgree,
    } = this.state;
    const { modelList, t, modelConfigs, datasets } = this.props;
    const modelDict = groupById(modelList || []);

    const headCells = [
      { _id: "name",          width: "20%", label: t("train.trained_models"), textSearch: true },
      { _id: "type",          width: "5%",  label: t("train.nlu_type"),         filterOn: true },
      { _id: "trainSet",      width: "20%", label: t("train.train_set"),        filterOn: true,   optional: true },
      { _id: "model_config",  width: "15%", label: t("train.config"),           filterOn: true,   optional: true },
      { _id: "description",   width: "22%", label: t("common.description"),   textSearch: true },
      { _id: "finishTrainAt", width: "10%", label: t("train.finishTrainAt"),    dateTime: true },
      { _id: "deployedAt",    width: "10%", label: t("train.deployedAt"),       dateTime: true,   optional: true },
      {
        _id: "status",        width: "8%",  label: t("common.status"),
        link: row => {
          this.setState({ showModelStatusDialog: true, editModelId: row._id });
        },
        style: trainStatusLinkStyle(row => [row, this.isTraining(row)], { External: 'green' }),
      },
    ];

    function addModelInfo(m) {
      const trainSet = m.trainSet.map(td_id => (datasets || []).find(d => d._id == td_id)?.name || '???').join(', ');
      const model_config = (modelConfigs || []).find(mc => mc._id == m.model_config)?.name || '???' 
      return {...m, trainSet, model_config };
    }

    return (
      <Fragment>
        {showModal && <ConfirmDialog
          title={dialogTitle}
          content={modalContent}
          closeModal={this.handleCloseModal}
          btnNameAgree={btnNameAgree}
        />}
        {downloadModal && <ConfirmDialog
          title={t("train.download_model_files")}
          content={downloadModal}
          closeModal={() => this.setState({ downloadModal: undefined })}
          showBtnNameAgree={false}
        />}
        {editDialogOpen && <EditDialog
          editModel={modelList.find(model => model._id == editModelId)}
          _id={editModelId}
          isOpen={editDialogOpen || false}
          onClose={this.handleCloseEditDialog}
        />}
        <ModelStatusDialog
          isOpen={this.state.showModelStatusDialog}
          model={modelDict[editModelId]}
          onClose={() => { this.setState({ showModelStatusDialog: false }) }}
          runnable={this.runnable}
        />
        {modelList && <EnhancedTable
          id="trained"
          headCells={headCells}
          passedPage={true}
          rows={modelList.map(addModelInfo)}
          viewCell={{ status: viewTrainStatus }}
          toolBarName={t("train.trained_models")}
          newRowTitle={t("train.new")}
          handleClickNewRow={() => this.handleClickNewRow()}
          handleClickUpdateRow={() => this.getModelList()}
          rowsOnPage={setDefaultRowsOnPage(modelList.length)}
          checkBoxTableCell={(id, name, index) => (
            <TableCell padding="default">{index + 1}</TableCell>
          )}
          customBtns={(name, id) => {
            const model = modelDict[id] || {};
            const isProcess = this.isTraining(model);
            const isReady = model.status === "Ready";
            const label = t(isProcess ? "common.stop" : "common.train");
            const ICONS = [
              ['edit',      this.handleEditRow,       EditIcon],
              ['clone',     this.handleCloneRow,      FileCopyIcon],
              ['download',  this.handleDownloadModel, GetAppIcon],
              ['delete',    this.handleDeleteRow,     DeleteIcon],
            ];
            const more = i => i[0] == 'download'
              && (model.type == 'NLU_RASA' || !model.remote_name)? { disabled: true } : {};
            return <div style={{width: "25%", display: "flex"}}>
              {!getRunConfigOption('trainTestIcons') ? <>
                <span>
                  <Button style={{marginRight: 10}} variant="outlined" size="small"
                    onClick={() => this.fetchGetTrainAction(id, isProcess ? "stop" : "train")}
                    disabled={model.is_external || isProcess && !this.runnable.isObjRunning(model)}
                  >
                    {label}
                  </Button>
                </span>
                <span>
                  <Button style={{marginRight: 10}} variant="outlined" size="small" onClick={() => this.fetchGetTrainAction(id, "deploy")}
                    disabled={model.type != 'NLU_RASA' || !isReady}>
                    {t("common.deploy")}
                  </Button>
                </span>
              </> : <>
                <IconButton
                  onClick={() => this.fetchGetTrainAction(id, isProcess ? "stop" : "train")}
                  disabled={isProcess && !this.runnable.isObjRunning(model)}
                  title={label}
                  Icon={isProcess ? BlockIcon : BrightnessHighIcon}
                />
                <IconButton
                  style={{marginRight: 10}}
                  onClick={() => this.fetchGetTrainAction(id, "deploy")}
                  disabled={!isReady}
                  title={t('common.deploy')}
                  Icon={PublishIcon}
                />
              </>}
              {ICONS.map(i => <IconButton
                key={i[0]}
                onClick={i[1](id, name)}
                title={t("common."+ i[0])}
                Icon={i[2]}
                {...more(i)}
              />)}
            </div>;
          }}
        />
        }
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  projectId: state.settings.projectInfo.projectId,
  projects: state.settings.projects,
  modelList: state.model.modelList,
  modelConfigs: state.settings.modelConfigList,
  datasets: state.settings.projectDatasets,
});

export default withRouter(connect(mapStateToProps)(withTranslation()(Train)));
