import { createSlice } from '@reduxjs/toolkit';
import { getDataNew as getData } from '../core/fetchService'

export const lookup = createSlice({
  name: 'lookup',
  initialState: {
    entities: null
  },
  reducers: {
    setEntities: (state, action) => {
        state.entities = action.payload;
    },
  },
});

export const { setEntities } = lookup.actions;

// not used
// export const getEntities = (state) => state.lookup.entities;

export default lookup.reducer;

export const fetchAllEntities = obj => dispatch => {
  const { projectId } = obj;
  getData(`/api/lookup?project=${projectId}`, dispatch, data => {
    dispatch(setEntities(data.lookups));
  })
};
