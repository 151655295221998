import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";

import { getRunConfigOption }         from "../../../core/utils";
import { viewLink }                   from "../../components/projectTable";

export function viewStatus(row, cell, { s_obj, percent, in_progress }) {
  const q_pos = s_obj?.progress?.queued;

  return viewLink(row, cell, s_obj?.status != in_progress +'...' ? undefined : _ => (
    getRunConfigOption('ourConditions')
      ? q_pos !== undefined ? `Queued: ${q_pos+1}` : `${in_progress}: ${Math.floor(percent || 0)}%`
      : <LinearProgress
          value={percent}
          variant={percent !== undefined ? 'determinate' : 'indeterminate'}
        />
  ));
}

export const linkStyle = (getStatusObj, add_colors) => row => {
  const [s_obj, is_running] = getStatusObj(row);
  const status = !is_running || s_obj?.progress?.queued === undefined
    ? (s_obj?.status || '').replace(/\W.*/,'')
    : 'Queued';
  const colors = {
    Error: 'red',
    Ready: 'green',
    Aborting: 'darkblue',
    Aborted: 'black',
    Stopped: 'black',
    Lost: 'grey',
 // Waiting: 'lightblue',
 // Queued: '#12afe3',
    ...add_colors,
  };
  return { color: colors[status] || "blue" };
};

export function Runnable({
    taskNames,
    getTaskName,
    getStatus,
    statusName,
    updateStatus,
    REFRESH_INTERVAL = 5,
    addActiveStatuses = [],
}) {
  this.objs = null;

  this.isStatusRunning = s => s == statusName +'...';
  this.isStatusActive = s =>
    ['Preparing', 'Sent', statusName, 'Aborting', 'Waiting', ...addActiveStatuses].map(s => s +'...').includes(s);

  const isMyTask = o => !taskNames || taskNames.includes(getTaskName(o));
  this.isObjRunning = o => isMyTask(o) && this.isStatusRunning(getStatus(o));
  this.isObjActive =  o => isMyTask(o) && this.isStatusActive(getStatus(o));

  const getObjById =  (objs, id) => objs.find(o => o._id == id);

  this.isRunning =  (objs, id) => this.isObjRunning(getObjById(objs, id));
  this.isActive =   (objs, id) => this.isObjActive(getObjById(objs, id));

  const isAnyObjActive = objs => !objs || objs.find(this.isObjActive);

  const setRefreshInterval = () => { this._timer = setInterval(_updateStatus, REFRESH_INTERVAL*1000) }
  const clearRefreshInterval = () => clearInterval(this._timer);

  this.componentDidUpdate = objs => {
    this.objs = objs;
    clearRefreshInterval();
    isAnyObjActive(objs) && setRefreshInterval();
  }
  this.componentWillUnmount = () => {
    this.objs = null;
    clearRefreshInterval();
  }

  const _updateStatus = () => {
    const active_objs = (this.objs || []).filter(this.isObjActive);
    updateStatus(active_objs);
  }
}

