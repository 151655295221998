import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  setIsLoading,
  getProjectId,
  getProjects,
} from "../../../features/settings";
import {
  fetchModelFilters,
} from "../../../features/modelFilters";
import { setMessageState } from "../../../features/messageInfo";
import { getDataNew, postDataNew, putDataNew } from "../../../core/fetchService";
import { MESSAGE_STATUS, BTN, NLU_TYPE_AC_OPTS } from "../../../core/constants";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import YAML from "yaml";
import { EditConfig } from "./editConfig";
import { Controlled as CodeMirror } from "react-codemirror2";
import CloseBar from "../../components/dialogCloseBar";
import MultiSelect from "../../components/material-ui/MultiSelect";
import { usePrevious, filterKeys } from "../../../core/utils";
import notBackdropClicked from "../../components/helpers/notBackdropClicked";

const NLUTypes = {
  NLU_RASA: "nluConfig",
  NLU_NB: "similarityConfig",
  ASR_E2E: "asrConfig",
};

const useStyles = makeStyles((theme) => ({
  fieldset: {
    margin: "10px",
    padding: "20px",
    borderRadius: 5,
    borderWidth: 1,
    color: "#C4C4C4"
  },
  dialogField: {
    margin: 8,
    paddingRight: 17
  },
  dialogInnerField: {
    paddingRight: 17
  },
  legend: {
    color: "#757575"
  },
  checkBox: {
    marginLeft: 10,
  },
}));

const useModalEditConfig = () => {
  const [isShowingEditConfig, setIsShowingEditConfig] = useState(false);

  function toggleEditConfig() {
    setIsShowingEditConfig(!isShowingEditConfig);
  }

  return {
    isShowingEditConfig,
    toggleEditConfig,
  };
};

function EditDialog(props) {
  const classes = useStyles();
  
  const projectId = useSelector(getProjectId);
  const projects = useSelector(getProjects);

  const initialEditModel = {
    project: projectId,
    name: null,
    description: '',
    is_external: false,
    trainSet: [],
    nluConfigText: null,
    nlu_train_url: null,
    nlu_deploy_url: null,
    model_config: null,
    type: null,
    include_data_schema: true,
    check_dupes: false,
  };

  const dispatch = useDispatch();
  const { isOpen, onClose, _id, editModel } = props;
  const modelFilters = props.modelFilters;
  const [configs, setConfigs] = useState(null);
  const [selectedConfig, setSelectedConfig] = useState(null);
  const [isPreviewConfigOpen, setIsPreviewConfigOpen] = useState(false);
  const [projectDataSets, setProjectDataSets] = useState(null);
  const [editModelParams, setEditModelParams] = useState(editModel || initialEditModel);
  const [configDefaults, setConfigDefaults] = useState();
  const prevModelType = usePrevious(editModelParams.type);

  const { isShowingEditConfig, toggleEditConfig } = useModalEditConfig();

  const { t } = useTranslation();

  function fetchData() {
    getDataNew(`/api/dataset?project=${projectId}`, dispatch, ({ datasets }) => {
      datasets && setProjectDataSets(datasets);
    });
  };

  useEffect(() => {
    if (!isOpen) {
      setEditModelParams(initialEditModel)
    }
  }, [isOpen]);

  useEffect(() => {
    getModelConfigs();
    getConfigDefaults();

    dispatch(fetchModelFilters(projectId));

    if (editModelParams.ext_type == 'NLU_RASA')
      setEditModelParams({ ...editModelParams, remote_name: editModelParams.filename });
  }, []);

  useEffect(() => {
    if (!projectDataSets) {
      fetchData();
    }
  }, [projects, _id]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = event => {
    setEditModelParams({ ...editModelParams, [event.target.id]: event.target.value });
  };

  const handleCheckboxChange = id => event => {
    setEditModelParams({ ...editModelParams, [id]: Boolean(event.target.checked) });
  };

  const handleChangeTrainSet = (event, value) => {
    setEditModelParams({ ...editModelParams, trainSet: value.map(v => v._id) })
  };

  const handleChangeConfig = (event, value) => {
    setEditModelParams({ ...editModelParams, model_config: value?._id ? value?._id : null })
  };

  const handleChangeNluType = (event, value) => {
    const project = projects?.find(p => p._id === projectId);
    const type = value?.value;
    const typeParams = project[NLUTypes[type]];

    const nlu_train_url = type !== "NLU_NB"? typeParams?.train_url: typeParams?.server;
    const nlu_deploy_url = type !== "NLU_NB"? typeParams?.deploy_url: null

    setEditModelParams({ ...editModelParams, type, nlu_train_url, nlu_deploy_url });
  };

  const handleChangeFilters = (event, value) => {
      setEditModelParams({
        ...editModelParams, 
        filters: (value || []).map(({ _id }, index) => ({
          filter: _id,
          index,
        })),
      })
  };

  const closeEditConfig = () => { toggleEditConfig() };
  const doCancel = () => { onClose(BTN.CANCEL) };

  const doGetSchema = () =>
    getDataNew(`/api/project/${projectId}/data_schema`, dispatch, data => {
      setEditModelParams({ ...editModelParams, nluConfigText: YAML.stringify(data) || null });
    });

  const doSave = async () => {
    const save = {
      method: _id ? putDataNew : postDataNew,
      uri: _id ? `/api/model/${_id}` : `/api/model`,
    };

    await save.method(
        save.uri,
        { model: editModelParams },
        dispatch,
        data => {
          dispatch(
              setMessageState({
                snackBarMessages: t("common.save_success"),
                snackBarVariant: MESSAGE_STATUS.SUCCESS,
                snackBarState: true,
              }),
          );
          // Success message only in one place (if needed will be deleted)

          onClose(BTN.SAVE);
    })
  };

  const getModelConfigs = () =>
    getDataNew(`/api/model_config`, dispatch, data => {
      const configs = data["model_configs"]
        .filter(c => c.scope == "custom" && c.project == projectId || c.scope == "public")
        .map(c => filterKeys(c, ['_id', 'scope', 'name', 'type']));
      setConfigs(configs);
    });

  const getModelConfig = id =>
    getDataNew(`/api/model_config/${id}`, dispatch, data => setSelectedConfig(data.model_config));

  const getConfigDefaults = () =>
    getDataNew('/api/project/named_configs', dispatch, data => setConfigDefaults( data));

  const getDefaultValue = (type, name, defaults) =>
    type ? defaults?.[NLUTypes[type]]?.[name] : '';

  const handlePreviewConfig = () => {
    setIsPreviewConfigOpen(!isPreviewConfigOpen);
  };

  const internal = () => (
    <>
      <fieldset className={classes.fieldset}>
        <legend>{t('train.data')}</legend>
        <Grid container spacing={3}>
          <Grid item sm={12}>
            <MultiSelect
              id="trainSet"
              fullWidth
              required
              options={projectDataSets || []}
              onChange={handleChangeTrainSet}
              getOptionLabel={(option) => option?.name}
              value={editModelParams.trainSet.map((id) =>
                  (projectDataSets || []).find((ds) => ds._id === id),
              )}
              label={t("train.train_set")}
            />
          </Grid>
          <Grid item sm={12}>
            <Autocomplete
              id="filters"
              size="small"
              multiple
              fullWidth
              required
              filterSelectedOptions
              options={modelFilters || []}
              disableCloseOnSelect
              onChange={handleChangeFilters}
              getOptionLabel={(option) => option?.name}
              value={(editModelParams.filters || []).map(({ filter }) =>
                  (modelFilters || []).find((mf) => mf._id === filter),
              )}
              renderOption={(option, { selected }) => (
                <React.Fragment>{option?.name}</React.Fragment>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label={t("train.filters")}/>
              )}
            />
            <div style={{ display: "-webkit-box", marginTop: "24px" }}>
              {editModelParams.type == 'NLU_RASA' ?
                <FormControlLabel
                  control={<Checkbox/>}
                  label={t("train.include_data_schema")}
                  checked={editModelParams.include_data_schema === undefined  // for old models
                      || editModelParams.include_data_schema}
                  onChange={handleCheckboxChange('include_data_schema')}
                /> : null}
              <Button
                id="btnEdit"
                variant="outlined"
                size="small"
                onClick={() => {
                  doGetSchema()
                    .then(() => toggleEditConfig());
                }}
              >
                {t("train.preview_data_schema")}
              </Button>
            </div>
          </Grid>
        </Grid>
      </fieldset>
      <fieldset className={classes.fieldset}>
        <legend className={classes.legend}>NLU</legend>
        <Autocomplete
          id="type"
          size="small"
          fullWidth
          required
          className={classes.dialogField}
          options={NLU_TYPE_AC_OPTS}
          onChange={handleChangeNluType}
          getOptionLabel={(option) => option.name || ""}
          value={NLU_TYPE_AC_OPTS.find(item => item.value === editModelParams.type) || null}
          getOptionSelected={(option, value) => option.value === value.value || true}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label={t("train.nlu_type")}/>
          )}
        />
        {editModelParams.type == 'ASR_E2E' &&
        <FormGroup style={{ marginLeft: 8 /* as in dialogField */ }}>
          <FormControlLabel
            // className={classes.dialogField}
            control={<Checkbox/>}
            label={t("train.check_dupes")}
            checked={editModelParams.check_dupes}
            onChange={event => {
              setEditModelParams({ ...editModelParams, check_dupes: event.target.checked });
            }}
          />
        </FormGroup>}
        {editModelParams.type && configs && <div style={{ display: "flex" }}>
          <Autocomplete
            options={configs.filter(config => config.type === editModelParams.type) || []}
            groupBy={(option) => option.scope}
            id="config"
            required
            size="small"
            label={"Config"}
            onChange={handleChangeConfig}
            variant="outlined"
            getOptionLabel={(option) => option.name || ""}
            value={configs.find(config => config._id === editModelParams.model_config) || null}
            getOptionSelected={(option, value) => option._id === value._id || true}
            fullWidth
            renderInput={(params) => (
              <TextField
                className={classes.dialogField}
                required
                {...params}
                variant="outlined"
                label={t("train.config")}
              />
            )}
          />
          <Button
            disabled={!editModelParams.model_config}
            variant="outlined"
            style={{ height: "40px", marginTop: "8px" }}
            onClick={() => {
              getModelConfig(editModelParams.model_config);
              handlePreviewConfig();
            }}>
            {t("train.preview_config")}
          </Button>
        </div>}
        {editModelParams.type && <TextField
          id="nlu_train_url"
          required
          size="small"
          className={classes.dialogField}
          label={t("train.train_test_url")}
          onChange={handleChange}
          variant="outlined"
          fullWidth
          value={editModelParams.type && editModelParams.nlu_train_url || ""}
          placeholder={getDefaultValue(editModelParams.type, 'train_url', configDefaults)}
        />}
        {editModelParams.type == 'NLU_RASA' && <TextField
          id="nlu_deploy_url"
          required
          size="small"
          className={classes.dialogField}
          label={t("train.runtime_url")}
          onChange={handleChange}
          variant="outlined"
          fullWidth
          value={editModelParams.type && editModelParams.nlu_deploy_url || ""}
          placeholder={getDefaultValue(editModelParams.type, 'deploy_url', configDefaults)}
        />}
      </fieldset>
    </>
  );

  const handleChangeExtType = (event, value) => {
    const ext_type = value?.value || null;
    const type = ext_type?.replace(/_Runtime/, '');
    setEditModelParams({ ...editModelParams, type, ext_type })
  };

  const external = () => {
    const EXT_TYPES = [/* 'NLU_RASA', 'NLU_NB', */ 'ASR_E2E'];
    const EXT_TYPE_OPTS = EXT_TYPES.map(t => ({ name: t, value: t +'_Runtime' }));
    return (<>
      <fieldset className={classes.fieldset}>
        <legend>{t('train.ext_model')}</legend>
        <Grid container spacing={3}>
          <Grid item sm={12}>
            <Autocomplete
              id="ext_type"
              size="small"
              fullWidth
              required
              className={classes.dialogInnerField}
              options={EXT_TYPE_OPTS}
              onChange={handleChangeExtType}
              getOptionLabel={option => option.name || ""}
              value={EXT_TYPE_OPTS.find(item => item.value == editModelParams.ext_type) || null}
              getOptionSelected={(option, value) => option.value == value.value || true}
              renderInput={params => (
                <TextField {...params} variant="outlined" label={t("train.ext_type")}/>
              )}
            />
          </Grid>
          {!['NLU_RASA_Runtime', 'NLU_NB_Runtime'].includes(editModelParams.ext_type) && (<>
            <Grid item sm={12}>
              <TextField
                id="nlu_train_url"
                required
                size="small"
                className={classes.dialogInnerField}
                label={t("train.ext_url")}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                value={editModelParams.nlu_train_url || ""}
              />
            </Grid>
            <Grid item sm={12}>
              <TextField
                id="remote_name"
                required
                size="small"
                className={classes.dialogInnerField}
                label={t("train.ext_name")}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                value={editModelParams.remote_name || ""}
              />
            </Grid>
          </>)}
          {editModelParams.ext_type == 'NLU_RASA_Runtime' && (
            <Grid item sm={12}>
            </Grid>
          )}
          {editModelParams.ext_type == 'NLU_NB_Runtime' && (
            <Grid item sm={12}>
            </Grid>
          )}
        </Grid>
      </fieldset>
    </>);
  };

  const ext_must_have_fields = [
    'name', 'ext_type', 'nlu_train_url',
    ...(editModelParams.ext_type == 'ASR_E2E_Runtime' ? [] : ['remote_name']),
  ];

  return (
      <Dialog
        maxWidth="sm"
        fullWidth={true}
        open={isOpen}
        onClose={notBackdropClicked(doCancel)}
        scroll="paper"
        aria-labelledby="edit-model-dialog-title"
      >
        <CloseBar onClose={doCancel} title={_id ? t("train.edit") : t("train.new")}/>
        <DialogContent dividers={true}>
          {isPreviewConfigOpen &&
          <Dialog
            fullWidth={true}
            open={isPreviewConfigOpen}
            onClose={handlePreviewConfig}
            scroll="paper"
            maxWidth="sm"
          >
            <CloseBar onClose={handlePreviewConfig} title={selectedConfig?.name}/>
            <CodeMirror
              value={selectedConfig?.data}
              options={{
                mode: "yaml",
                lineNumbers: true,
              }}
              readOnly
            />
          </Dialog>
          }
          <TextField
            id="name"
            required
            size="small"
            className={classes.dialogField}
            label={t("train.model_name")}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            defaultValue={editModelParams.name || ""}
          />
          <TextField
            id="description"
            size="small"
            className={classes.dialogField}
            label={t("common.description")}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            defaultValue={editModelParams.description || ""}
          />
          {_id ? null :
          <FormControlLabel
            style={{marginLeft: 0}}
            control={<Checkbox/>}
            label={t("train.is_external")}
            checked={editModelParams.is_external || false}
            onChange={handleCheckboxChange('is_external')}
          />}
          {editModelParams.is_external ? external() : internal()}
        </DialogContent>
        <DialogActions>
          <Button onClick={doCancel}>{t("common.cancel")}</Button>
          <Button
            disabled={editModelParams.is_external
              ? ext_must_have_fields.some(k => !editModelParams[k])
              : ['model_config', 'name', 'trainSet', 'type', 'nlu_train_url'].some(k => !editModelParams[k])}
            onClick={doSave} color="primary" autoFocus
          >
            {t("common.save")}
          </Button>
        </DialogActions>
        <EditConfig
          isOpen={isShowingEditConfig}
          onClose={closeEditConfig}
          nluConfigText={editModelParams.nluConfigText}
        />
      </Dialog>
  );
}

const mapStateToProps = (state) => ({
  projectId: state.settings.projectInfo.projectId,
  modelFilters: state.modelFilters.filters,
});

export default connect(mapStateToProps)(EditDialog);
