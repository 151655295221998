import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { setMessageState } from '../../../features/messageInfo';
import { postDataNew, deleteDataNew, putDataNew, uploadFileNew } from '../../../core/fetchService'
import { MESSAGE_STATUS, DIALOG_USER_STATE } from '../../../core/constants';
import { fetchIntents, setIntentsList } from '../../../features/annotationSettings';
import { delay, setDefaultRowsOnPage, download, GlobalState,
  showSuccess, showError, useTranslation, withTranslation, getEnding, getPassiveEnding } from '../../../core/utils';
import EnhancedTable from '../../components/projectTable';
import IconButton from '../../components/material-ui/IconButton';
import iconList from '../../components/helpers/iconList';
import DeleteIcon from '@material-ui/icons/Delete';
import TableCell from '@material-ui/core/TableCell';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import ConfirmDialog from '../../components/confirmDialog'
import Tooltip from '@material-ui/core/Tooltip';
import PublishIcon from '@material-ui/icons/Publish';
import GetAppIcon from '@material-ui/icons/GetApp';
import { setIsLoading } from '../../../features/settings';
import Upload from '../../components/fieldUpload';
import { withRouter } from "react-router";
import CloseBar from "../../components/dialogCloseBar";
import notBackdropClicked from "../../components/helpers/notBackdropClicked";
import Scrollable from '../../components/helpers/scrollable';

function EditDialog(props) {
  const { isOpen, comboData, onClose } = props;
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const { t } = useTranslation();

  const handleClose = (isOpen) => {
    if (!isOpen) clearForm()

    onClose({ name, description }, isOpen);
  }

  const clearForm = () => {
    setName("");
    setDescription("");
  };

  return (
    <Dialog
      fullWidth={true}
      open={isOpen}
      onClose={notBackdropClicked(() => handleClose(false))}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
    >
      <CloseBar onClose={() => handleClose(false)} title={t("intents.add_new_intent")}/>
      <DialogContent dividers style={{ overflow: 'hidden' }}>
        <TextField
          required size="small" style={{ margin: 8 }}
          id="intentName"
          label={t('intents.intent')}
          onChange={(event) =>{
            let value = event.target.value;
            value = value.replace(/[^0-9A-Za-z-_.]/gi, '');
            setName(value);
          }}
          variant="outlined"
          value={name || ''}
          fullWidth
          error={name === ""}
        />
        <TextField
          required size="small" fullWidth
          id="description"
          label={t('common.description')}
          multiline
          rowsMax="4"
          style={{ margin: 8 }}
          onChange={(event) => setDescription(event.target.value)}
          variant="outlined"
          //error={description === ""}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false)} color="primary">
          {t('common.cancel')}
        </Button>
        {/* eslint-disable-next-line no-undef */}
        <Button disabled={!name} onClick={() => handleClose(true)} color="primary" autoFocus>
          {t('common.save')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

class Intents extends React.Component {
  constructor(props) {
    super(props);
    const { t } = props;
    this.state = {
      showModal: false,
      modalContent: null,
      rowId: null,
      name: null,
      editDialogOpen: false,
      forceDelete: false,
    };

    this.scrollable = new Scrollable(this);
    this.globalState = new GlobalState(this.getAllIntents);
  }

  getAllIntents = () => this.props.dispatch(fetchIntents({ projectId: this.props.projectId }));

  componentDidMount()           { this.scrollable.componentDidMount(); this.globalState.componentDidMount() }
  componentDidUpdate(prevProps) { this.globalState.componentDidUpdate(prevProps, this.props) }

  handleDeleteRow = (id, name) => {
    this.setState({
      modalContent: `${this.props.t('intents.delete_question')}: "${name}"?`,
      showModal: true,
      rowId: id,
      name,
    });
  };

  handleCloseModal = async modalState => {
    const { dispatch, t } = this.props;
    const { rowId, name, forceDelete } = this.state;
    this.setState({ showModal: false, forceDelete: false });
    if (!rowId)
      return showError(dispatch, t)('NS-client error: expected row id');
    if (modalState != DIALOG_USER_STATE.AGREE)
      return;
    const data = await deleteDataNew(`/api/intent/${rowId}${forceDelete? '?force=true' : ''}`, dispatch, data => {
      this.getAllIntents();
    });
    if (data.error?.id == 'used') {
      const { num_rows, num_datasets, num_sample_rows, sample_rows, rows_per_dataset } = data.error.details;
      this.setState({
        modalContent: (<>
          <p>{t('intents.delete_error__used', getPassiveEnding('of_samples', num_rows, t),
              num_rows, getEnding('of_samples', num_rows, t),
              num_datasets, getEnding('of_datasets', num_datasets, t))}</p>
          <p>{t('intents.delete_error__first_rows_are', num_sample_rows, JSON.stringify(sample_rows))}</p>
          {num_sample_rows < num_rows
              && <p>{t('intents.delete_error__rows_per_dataset', JSON.stringify(rows_per_dataset))}</p>}
          <p style={{fontWeight: 'bold'}}>{t('intents.force_delete_question', name)}</p>
        </>),
        showModal: true,
        forceDelete: true,
      });
    }
  };

  handleClickNewRow = () => this.setState({ editDialogOpen: true });

  handleClose = (dialogState, isOpen) => {
    const { name } = dialogState;
    if (!isOpen || !name)
      return this.setState({ editDialogOpen: false });

    dialogState = { ...dialogState, project: this.props.projectId };

    postDataNew(`/api/intent`, { "intent": dialogState }, this.props.dispatch, data => {
      this.getAllIntents();
      this.setState({ editDialogOpen: false });
    });
  };

  handleRowItemChanged = obj => {
    const { dispatch, intents, projectId } = this.props;
    const body = {
      intent: { name: obj.name, description: obj.description, project: projectId }
    };
    putDataNew(`/api/intent/${obj._id}`, body, dispatch, data => {
      dispatch(setIntentsList(intents.map(p => p._id == data.intent._id ? data.intent : p)));
    }, {
      error_prefix: `[intent '${obj.name}' update]`,
    });
  };

  handleClickDownLoadIntent = () => {
    const { projectId, dispatch } = this.props;
    download(`/api/intent/${projectId}/download`, dispatch);
  };

  handleClickUploadIntent = (fileType, formData) => {
    const { projectId, t, dispatch } = this.props;
    uploadFileNew(`/api/intent/${projectId}/upload`, formData, dispatch, data => {
      this.getAllIntents();
    }, {
      error_prefix: t('intents.upload_intent_error'),
    });
  };

  render() {
    const { modalContent, showModal, editDialogOpen } = this.state;
    const { t, intents } = this.props;

    const headCells = [
      {
        _id: 'name',
        label: t('intents.intent'),
        width: "40%",
        customTextField: true,
        textSearch: true,
        regularExpr: /[^\w.-]/gi,
      },
      {
        _id: 'description',
        label: t('common.description'),
        width: "45%",
        customTextField: true,
        align: 'left',
        multiline: true,
        textSearch: true,
      },
    ];

    return (
      <Fragment>
        {showModal && <ConfirmDialog
          title={t('intents.confirm_title')}
          content={modalContent}
          closeModal={this.handleCloseModal}
        />}
        <EditDialog isOpen={editDialogOpen} onClose={(obj, isOpen) => this.handleClose(obj, isOpen)}/>
        {intents && <EnhancedTable
          id="intents"
          headCells={headCells}
          passedPage={true}
          rows={intents}
          toolBarName={t('menu.intents')}
          newRowTitle={t("intents.add_new_intent")}
          rowsOnPage={setDefaultRowsOnPage(intents.length)}
          handleClickNewRow={this.handleClickNewRow}
          handleRowItemChanged={delay(this.handleRowItemChanged, 300)}
          handleClickUpdateRow={() => this.getAllIntents()}
          checkBoxTableCell={(id, name, index) => (
            <TableCell padding="default">{index + 1}</TableCell>
          )}
          customHeaderButtons={() => {
            return <Fragment>
              <Upload
								btnComponent={<IconButton title={t('common.upload')} Icon={PublishIcon} component="span"/>}
                fileNameShow={false}
                size="small"
                onFileLoad={(fileType, formData) => this.handleClickUploadIntent(fileType, formData)}
              />
              <div style={{margin: 10}}>
                {iconList([
                  ['common.download', GetAppIcon, this.handleClickDownLoadIntent],
                ], { t })}
              </div>
            </Fragment>
          }}
          customBtns={(name, id) => (
            <div style={{width: "10%", display: "flex"}}>
              {iconList([
                ['common.delete', DeleteIcon, () => this.handleDeleteRow(id, name)],
              ], { t })}
            </div>
          )}
        />
        }
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  projectId: state.settings.projectInfo.projectId,
  intents: state.annotationSettings.intentsList,
});

export default withRouter(connect(mapStateToProps)(withTranslation()(Intents)));
