import React, { Fragment, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router";
import { fetchAutoModelList, fetchDataSets, fetchTestModelList, setIsLoading } from "../../../features/settings";
import { setDefaultRowsOnPage, getRunConfigOption } from "../../../core/utils";
import { setMessageState } from "../../../features/messageInfo";
import { getDataNew, postDataNew, putDataNew, deleteDataNew } from "../../../core/fetchService";
import TextField from "@mui/material/TextField";
import { MESSAGE_STATUS, BTN, DIALOG_USER_STATE } from "../../../core/constants";
import EnhancedTable from "../../components/projectTable";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import TableCell from "@material-ui/core/TableCell";
import Button from "@material-ui/core/Button";
import ConfirmDialog from "../../components//confirmDialog";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { withTranslation, useTranslation } from "react-i18next";

import EditDialog from "./editBatchDialog";
import { BatchStatusDialog } from "./batchStatusDialog";
import { FormControl } from "@mui/material";
import { fetchModelList } from "../../../features/model";

import {
  /* viewStatus, linkStyle, */ Runnable,
}                                           from "../../components/taskStatus";

const Batches = ({ projectId, modelList, testModelList, autoModelList, projectDatasets }) => {
  const [batches, setBatches] = useState(null);
  const [selectedBatchId, setSelectedBatchId] = useState(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [showBatchStatusDialog, setShowBatchStatusDialog] = useState(false);
  const [statusBody, setStatusBody] = useState({});
  const [cloningBatchName, setCloningBatchName] = useState("");
  const [statusBatch, setStatusBatch] = useState({});
  const [batchDialogState, setBatchDialogState] = useState({
    state: undefined,
    name: "",
    isOpen: false,
    content: "",
    batchId: "",
    batch: null,
  });

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const fetchBatches = () => getDataNew(`/api/batch?project=${projectId}`, dispatch, data => {
    setBatches(data.batches.map(batch => ({
      ...batch,
      status: batch.execution.status || null,
      batchSteps: batch.steps.map((item, idx) => ({ name: item.name, index: idx })),
    })))});

  const runnable = new Runnable({
    getStatus: batch => batch?.execution?.status,
    statusName: 'Batch',
    updateStatus: fetchBatches,
    addActiveStatuses: ['Load'],
  });

  useEffect(() => {
    dispatch(fetchTestModelList({ projectId }));
    dispatch(fetchModelList({ projectId }));
    dispatch(fetchDataSets({ projectId }));
    dispatch(fetchAutoModelList({ projectId }));

    fetchBatches();
  }, [projectId]);

  useEffect(() => {
    runnable.componentDidUpdate(batches);
    return () => {
      runnable.componentWillUnmount();
    };
  });

  const postBatch = (obj, clone) =>
      postDataNew(`/api/batch`, { "batch": clone ? { ...obj, name: cloningBatchName } : obj }, dispatch);

  const deleteBatch = id => deleteDataNew(`/api/batch/${id}`, dispatch);

  const updateBatch = ({ _id, status, steps, ...rest }) =>
    putDataNew(`/api/batch/${_id}`, { "batch": rest }, dispatch);

  const handleDeleteDialogOpen = (batchId, batchName) => {
    setBatchDialogState({
      state: "delete",
      name: t("common.delete"),
      batchId,
      isOpen: true,
      content: `${t("batches.batch_delete")}: "${batchName}"?`,
      batch: undefined,
    });
  };

  const handleDeleteDialogClose = result => {
    if (result === DIALOG_USER_STATE.AGREE)
      deleteBatch(batchDialogState.batchId).then(fetchBatches);

    setBatchDialogState({
      state: undefined,
      name: undefined,
      isOpen: false,
      content: undefined,
      batchId: undefined,
      batch: undefined,
    });
  };

  const handleCloneDialogOpen = (batchId, batchName, batch) => {
    setBatchDialogState({
      state: "clone",
      name: t("batches.clone"),
      batchId,
      isOpen: true,
      content:
        <TextField
          onChange={(event, value) => {
            setCloningBatchName(event.target.value);
          }}
          defaultValue={batchName}
          size="small" style={{ margin: 8, paddingRight: 17 }}
          label={t("batches.name")}
          variant="outlined"
          fullWidth
        />,
      batch: batch,
    });
  };

  const handleCloneDialogClose = result => {
    if (result === DIALOG_USER_STATE.AGREE) {
      setCloningBatchName("");
      postBatch(batchDialogState.batch, true).then(fetchBatches);
    }

    setBatchDialogState({
      state: undefined,
      name: undefined,
      isOpen: false,
      content: undefined,
      configId: undefined,
      batch: undefined,
    });
  };

  const headCells = [
    { _id: "name", width: "20%", label: t("batches.name") },
    { _id: "type", width: "20%", label: t("batches.type") },
    { _id: "default", width: "10%", label: t("batches.default") },
    { _id: "skip_steps", width: "10%", label: t("batches.skip_steps") },
    {
      _id: "status",
      width: "20%",
      label: t("batches.status"),
      link: (row) => {
        setStatusBody({ ...row.execution })
        setStatusBatch(row);
        setShowBatchStatusDialog(true);
      },
      style: (row) => {
        if (row?.execution.status) {
          if (row.execution.status.indexOf("Error") >= 0)
            return { color: "red" };
          else if (row.execution.status.indexOf("Ready") >= 0)
            return { color: "green" };
          return { color: "blue" };
        }
      },
    },
  ];

  const handleClickRow = (id) => {
    setSelectedBatchId(id || null);
    setEditDialogOpen(true);
  };

  const handleCloseEditDialog = (action) => {
    setSelectedBatchId(null);
    setEditDialogOpen(false);

    if (action === BTN.SAVE)
      fetchBatches();
  };

  const runBatch = _id => getDataNew(`/api/batch/${_id}/run`, dispatch, () => {
    setTimeout(() => {
      dispatch(setMessageState({
        snackBarMessages: t("batches.start"),
        snackBarVariant: MESSAGE_STATUS.SUCCESS,
        snackBarState: true,
      }));
      fetchBatches();
    }, [1000])});

  const stopBatch = _id => getDataNew(`/api/batch/${_id}/stop`, dispatch, () => {
    setTimeout(() => {
      dispatch(setMessageState({
        snackBarMessages: t("batches.stop"),
        snackBarVariant: MESSAGE_STATUS.SUCCESS,
        snackBarState: true,
      }));
      fetchBatches();
    }, [1000])});

  return (
    <Fragment>
      {batchDialogState.isOpen && <ConfirmDialog
        title={t(`${batchDialogState.name}`)}
        open={batchDialogState.isOpen}
        content={batchDialogState.content}
        closeModal={batchDialogState.state === "delete" ? handleDeleteDialogClose : handleCloneDialogClose}
      />}
      {editDialogOpen && autoModelList && modelList && testModelList && projectDatasets &&
      <EditDialog
        {...{
          datasetsList: projectDatasets,
          autoModelList,
          testsList: testModelList,
          modelsList: modelList,
          updateBatch,
          postBatch,
          _id: selectedBatchId,
          isOpen: editDialogOpen,
          onClose: handleCloseEditDialog,
        }}
      />}
      {statusBatch && autoModelList && modelList && testModelList && projectDatasets &&
      <BatchStatusDialog isOpen={showBatchStatusDialog || false}
                         onClose={() => {
                           setShowBatchStatusDialog(false);
                         }}
                         testsList={testModelList}
                         autoModelList={autoModelList}
                         modelsList={modelList}
                         datasetsList={projectDatasets}
                         statusBatch={statusBatch}
                         batches={batches}
                         getBatches={fetchBatches}
                         statusBody={statusBody}/>}
      {batches && <EnhancedTable
        id="batches_table"
        passedPage={true}
        headCells={headCells}
        rows={batches.map(b => ({...b, default: b.default ? '+' : '', skip_steps: (b.skip_steps || []).join(', ') }))}
        toolBarName={t("menu.batches")}
        newRowTitle={t("batches.new_batch")}
        handleClickNewRow={() => handleClickRow()}
        handleClickUpdateRow={fetchBatches}
        rowsOnPage={setDefaultRowsOnPage(batches.length)}
        checkBoxTableCell={(id, name, index) => (
          <TableCell padding="default">{index + 1}</TableCell>
        )}
        customBtns={(name, id) => {
          const batch = batches?.find(b => b._id === id) || { batchSteps: [] };
          const isProcess = runnable.isActive(batches, id);
          return <div style={{width: "25%", display: "flex"}}>
								<span style={{ marginRight: 10 }}>
									<Button variant="outlined" size="small" onClick={() => runBatch(id)} disabled={isProcess}>
										{t("common.start")}
									</Button>
								</span>
            {getRunConfigOption('startBatchFromStep') ?
            <FormControl size="small" style={{ marginRight: 10, width: 100 }}>
              <InputLabel style={{ fontSize: 14 }} id={`${t("batches.step")}_label`}>{t("batches.step")}</InputLabel>
              <Select
                style={{ height: 30, fontSize: 13 }}
                id="step"
                labelId={`${t("batches.step")}_label`}
                value={batch.step_first}
                label={t("batches.step")}
                onChange={event => {
                  updateBatch({ ...batch, step_first: event.target.value }).then(fetchBatches);
                }}
              >
                {batch.batchSteps.map(
                  (item, idx) => <MenuItem style={{ fontSize: "13px" }} key={`${item.name}_${Math.random()}`}
                                           value={idx}>{`${idx + 1}.${item.name}`}</MenuItem>)}
              </Select>
            </FormControl> : null}
              <span>
              <Button variant="outlined" size="small" onClick={() => stopBatch(id)} disabled={!isProcess}>
              {t("common.stop")}
              </Button>
              </span>
            <IconButton size="small" aria-label="edit" onClick={() => handleClickRow(id)}>
              <Tooltip title={t("common.edit")}>
                <EditIcon/>
              </Tooltip>
            </IconButton>
            <IconButton size="small" aria-label={t("batches.clone")} onClick={() => {
              setCloningBatchName(name);
              handleCloneDialogOpen(id, name, batch);
            }}>
              <Tooltip title={t("batches.clone")}>
                <FileCopyIcon/>
              </Tooltip>
            </IconButton>
            <IconButton size="small" aria-label={t("common.delete")}
                        onClick={() => handleDeleteDialogOpen(id, name)}>
              <Tooltip title={t("common.delete")}>
                <DeleteIcon/>
              </Tooltip>
            </IconButton>
          </div>;
        }}
      />}
    </Fragment>
  );
};

const mapStateToProps = state => ({
  projectId: state.settings.projectInfo.projectId,
  projects: state.settings.projects,
  testModelList: state.settings.testModelList?.testmodels,
  modelList: state.model.modelList,
  projectDatasets: state.settings.projectDatasets,
  autoModelList: state.settings.autoModelList?.automodels,
  _id: state.model._id,
});

export default withRouter(connect(mapStateToProps)(withTranslation()(Batches)));
;
